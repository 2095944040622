import {Card, CollapsibleCardContent, SvgIcon, Pill} from '@shipwell/shipwell-ui';
import {AirMovement} from '@shipwell/locus-sdk';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {MovementDetailsItem} from './MovementDetailsItem';
import {
  formatDistance,
  formatStatus,
  formatVolume,
  formatWeight,
  formatDateTime,
  formatAirportLocation
} from './customFormatters';
import {RollbarErrorBoundary} from 'App/common/ErrorBoundary';

interface AirMovementDetailsCardProps {
  movementDetail: AirMovement;
  shipment: Shipment;
}

export const AirMovementDetailsCard = ({movementDetail}: AirMovementDetailsCardProps) => {
  return (
    <Card
      className="mb-4"
      title={
        <div className="flex items-center py-4">
          <SvgIcon name="PlaneCircleOutlined" className="mr-3 shrink-0" />
          <span className="mr-3">United Airlines #{movementDetail.number}</span>
          <Pill size="sm">{formatStatus(movementDetail.status)}</Pill>
        </div>
      }
      isCollapsible
      draggableProvided={undefined}
    >
      <CollapsibleCardContent>
        <RollbarErrorBoundary errorMessage="There was an error displaying the movement details">
          <div className="border-bottom mb-2">
            <div className="grid grid-cols-3 gap-2">
              <MovementDetailsItem label="Origin" value={formatAirportLocation(movementDetail.origin)} />
              <MovementDetailsItem label="Destination" value={formatAirportLocation(movementDetail.destination)} />
              <MovementDetailsItem label="Total Distance" value={formatDistance(movementDetail.distance)} />
              <MovementDetailsItem label="Flight Distance" value={formatDistance(movementDetail.distance)} />
              <MovementDetailsItem label="Duration" value={movementDetail.duration} />
              <MovementDetailsItem label="Total Time" value={movementDetail.duration} />
              <MovementDetailsItem label="pieces" value={movementDetail.pieces?.toString()} />
              <MovementDetailsItem label="Volume" value={formatVolume(movementDetail.volume)} />
              <MovementDetailsItem label="Weight" value={formatWeight(movementDetail.weight)} />
              <MovementDetailsItem label="Additional Info" value={null} />
            </div>
          </div>
          <div className="border-bottom mb-2">
            <div className="mb-2">
              <b>Departure times</b>
            </div>
            <div className="grid grid-cols-3 gap-2">
              <MovementDetailsItem
                label="Scheduled Departure"
                value={formatDateTime(movementDetail.scheduled_departure)}
              />
              <MovementDetailsItem
                label="Estimated Departure"
                value={formatDateTime(movementDetail.estimated_departure)}
              />
              <MovementDetailsItem label="Actual Departure" value={formatDateTime(movementDetail.actual_departure)} />
            </div>
          </div>
          <div className="">
            <div className="mb-2">
              <b>Arrival times</b>
            </div>
            <div className="grid grid-cols-3 gap-2">
              <MovementDetailsItem label="Scheduled Arrival" value={formatDateTime(movementDetail.scheduled_arrival)} />
              <MovementDetailsItem label="Estimated Arrival" value={formatDateTime(movementDetail.estimated_arrival)} />
              <MovementDetailsItem label="Actual Arrival" value={formatDateTime(movementDetail.actual_arrival)} />
            </div>
          </div>
        </RollbarErrorBoundary>
      </CollapsibleCardContent>
    </Card>
  );
};
