import {
  Weight,
  Volume,
  Distance,
  AirMovementStatusValues,
  DistanceUnitValues,
  VolumeUnitValues,
  WeightUnitValues,
  AirportLocation
} from '@shipwell/locus-sdk';

const timeZones = [
  {code: 'EST', standardOffset: -5, dstOffset: -4, dstCode: 'EDT'},
  {code: 'CST', standardOffset: -6, dstOffset: -5, dstCode: 'CDT'},
  {code: 'MST', standardOffset: -7, dstOffset: -6, dstCode: 'MDT'},
  {code: 'PST', standardOffset: -8, dstOffset: -7, dstCode: 'PDT'},
  {code: 'AKST', standardOffset: -9, dstOffset: -8, dstCode: 'AKDT'},
  {code: 'HAST', standardOffset: -10, dstOffset: -10, dstCode: null}, // No DST
  {code: 'AST', standardOffset: -4, dstOffset: -3, dstCode: 'ADT'},
  {code: 'SST', standardOffset: -11, dstOffset: -10, dstCode: null}, // No DST
  {code: 'ChST', standardOffset: 10, dstOffset: 10, dstCode: null} // No DST
];

export const formatStatus = (status: AirMovementStatusValues | string) => {
  switch (status) {
    case AirMovementStatusValues.ArrivedLate:
      return 'Arrived Late';
    case AirMovementStatusValues.Arrived:
      return 'Arrived';
    case AirMovementStatusValues.Delayed:
      return 'Delayed';
    case AirMovementStatusValues.OnTime:
      return 'On Time';
    default:
      return status;
  }
};

export const formatDistance = (distance: Distance | undefined) => {
  if (!distance) {
    return null;
  }

  return `${distance.value} ${distance.units === DistanceUnitValues.Km ? 'km' : 'mi'}`;
};

export const formatWeight = (weight: Weight | undefined) => {
  if (!weight) {
    return null;
  }

  console.log('weight', weight.value);

  return `${weight.value.toString()} ${weight.units === WeightUnitValues.Lb ? 'lb' : 'kg'}`;
};

export const formatVolume = (volume: Volume | undefined) => {
  if (!volume) {
    return null;
  }

  return `${volume.value.toString()} ${volume.unit === VolumeUnitValues.Ft ? 'ft³' : 'm³'}`;
};

function isInDaylightSavingTime(date: Date): boolean {
  const year = date.getFullYear();
  // Daylight Saving Time rules for the contiguous U.S.
  // DST starts on the second Sunday in March and ends on the first Sunday in November
  const getSecondSundayInMarch = (year: number): Date => {
    const march = new Date(year, 2, 1); // March 1st
    const dayOfWeek = march.getDay(); // Get the day of the week (0-6)
    const daysToAdd = ((7 - dayOfWeek + 1) % 7) + 7; // Calculate days to second Sunday
    return new Date(year, 2, 1 + daysToAdd); // Return the second Sunday
  };

  const getFirstSundayInNovember = (year: number): Date => {
    const november = new Date(year, 10, 1); // November 1st
    const dayOfWeek = november.getDay(); // Get the day of the week (0-6)
    const daysToAdd = (7 - dayOfWeek) % 7; // Calculate days to first Sunday
    return new Date(year, 10, 1 + daysToAdd); // Return the first Sunday
  };

  const dstStart = getSecondSundayInMarch(year);
  const dstEnd = getFirstSundayInNovember(year);

  // Check if the date is within the DST range
  return date >= dstStart && date < dstEnd;
}

function getTimeZoneOffset(dateString: string) {
  // Regular expression to match the GMT offset
  const regex = /(GMT)([+-]\d)/;
  const match = dateString.match(regex);

  if (!match) {
    throw new Error('Invalid date format.');
  }

  // Extract the offset from the matched group
  return match[2];
}

function replaceTimeZoneOffset(dateString: string, timeZoneCode?: string | null): string {
  if (!timeZoneCode) {
    return dateString;
  }

  // Regular expression to match the GMT offset
  const regex = /(GMT)([+-]\d)/;

  // Replace the matched GMT offset with the new offset
  const updatedDateString = dateString.replace(regex, `${timeZoneCode}`);

  return updatedDateString;
}

export const formatDateTime = (dateString?: string) => {
  const offsetMatch = dateString?.match(/([+-]\d{2}):?(\d{2})$/);
  if (!dateString || !offsetMatch) {
    return null;
  }

  const date = new Date(dateString);
  const [, hours, minutes] = offsetMatch;
  const timezoneOffset = `${hours}:${minutes}`;

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    weekday: 'short',
    minute: '2-digit',
    timeZoneName: 'short',
    timeZone: timezoneOffset
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const isDstDate = isInDaylightSavingTime(date);
  const offset = getTimeZoneOffset(formatter.format(date));

  const timeZoneInfo = timeZones.find((tz) =>
    isDstDate ? tz.dstOffset === parseInt(offset, 10) : tz.standardOffset === parseInt(offset, 10)
  );
  const timeZoneCode = isDstDate ? timeZoneInfo?.dstCode : timeZoneInfo?.code;

  return replaceTimeZoneOffset(formatter.format(date), timeZoneCode);
};

// TODO: move this to a more general place
export const formatAirportLocation = (airportLocation?: AirportLocation | null) => {
  if (!airportLocation) {
    return null;
  }

  return airportLocation.region
    ? `${airportLocation.name}, ${airportLocation.region} (${airportLocation.code})`
    : `${airportLocation.name} (${airportLocation.code})`;
};
