import React, {useState, ElementType, MouseEvent} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Link, InjectedRouter} from 'react-router';
import {User, InboxMessage} from '@shipwell/backend-core-sdk';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {SvgIcon} from '@shipwell/shipwell-ui';
import Inbox from 'App/features/inbox';
import {
  ConditionalFuelSurchargesLink,
  ConditionalAccessorialRatesLink,
  ConditionalCustomerSettingsLink,
  ConditionalRfpLink,
  ConditionalCompassAlertsLink,
  ConditionalCarrierComplianceLink,
  ConditionalLoadOptimizationLink,
  ConditionalSettlementsConfigsLink
} from 'App/containers/userCompany/routes';
import {DocumentTypesLinks} from 'App/containers/templateManagement/links';
import {shipwellReleaseNotesLink} from 'App/common/mainHeader';
import {
  ConditionalCreateDrayageShipmentLink,
  ConditionalCreateIntermodalShipmentLink
} from 'App/containers/shipments/routes';
import {ConditionalServiceProvidersLink} from 'App/containers/serviceProviders/routes';
import './styles.scss';
import {companyContractDefaultsPath, companyRoutingGuideDefaultsPath} from 'App/containers/userCompany';
import {TenantPickerMobileMenuItem} from 'App/components/TenantPicker';
import MenuToggle from 'App/common/mainHeader/mobileNavigation/MenuToggle';
import RfpCarrierLink from 'App/containers/userCompany/rfps/RfpCarrierLink';
import {State} from 'App/reducers/types';
import {ConditionalSettlementsNavigationLink} from 'App/containers/settlements/freightInvoices/components/SettlementsDashboard/ConditionalNavigationLink';
import {useAppointments, useUserMe} from 'App/data-hooks';
import {
  PermissionsFallback,
  PermissionsWithQuotingLimitedUserFallback
} from 'App/components/permissions/PermissionsFallback';
import {
  CREATE_ORDERS_USER_PERMISSION,
  CREATE_QUOTES_USER_PERMISSION,
  CREATE_SHIPMENTS_USER_PERMISSION,
  VIEW_ADDRESS_BOOK_PERMISSION,
  UPDATE_SHIPMENTS_USER_PERMISSION,
  UPDATE_MY_SHIPMENTS_USER_PERMISSION
} from 'App/components/permissions/PermissionsFallback/constants';
import {ConditionalCreateOrderMobileNavigationLinks} from 'App/containers/orders/components';

type MobileNavigationContainerType = {
  userProfile: Partial<User>;
  onClose: () => void;
  hasCustomerViewPermission: boolean;
  hasShipmentUpdatePermission: boolean;
  hasCompanyUserViewPermission?: boolean;
  hasCompanyUserCreatePermission?: boolean;
  hasCompanyUserDeletePermission?: boolean;
  hasCompanyUserUpdatePermission?: boolean;
  hasCompanyDetailsViewPermission: boolean;
  hasCompanyAdministratorPermission: boolean;
  hasCompanyDetailsUpdatePermission: boolean;
  hasCarrierViewPermission: boolean;
  hasQuotesUpdatePermission: boolean;
  hasDispatchViewPermission: boolean;
  hasAnalyticsViewPermission: boolean;
  hasOrdersViewPermission: boolean;
  hasContractsViewPermission: boolean;
  hasContractsUpdatePermission: boolean;
  hasContractsDeletePermission: boolean;
  hasContractsCreatePermission: boolean;
  hasIntegrationsViewPermission: boolean;
  hasIntegrationsCreatePermission: boolean;
  hasIntegrationsManagePermission: boolean;
  hasIntegrationsRemovePermission: boolean;
  isPurchaseOrdersEnabled: boolean;
  isAnalyticsEnabled: boolean;
  isLoadBoardEnabled: boolean;
  isContractsEnabled: boolean;
  isSupplierInvitesEnabled: boolean;
  isRoutingGuidesEnabled: boolean;
  isBidManagerEnabled: boolean;
  isPricingIntelEnabled: boolean;
  isAlertsDashboardEnabled: boolean;
  isOrderConsolidationWorkflowsEnabled: boolean;
  isSupplierUser: boolean;
  hasAppointmentsViewPermission: boolean;
  hasSchedulingViewPermission: boolean;
  router: InjectedRouter;
  onShowTerms: () => void;
  onShowTasks: () => void;
  onShowPrivacyPolicy: () => void;
  inbox: (InboxMessage | undefined)[];
  handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  newAirShipmentHandler: () => void;
};

/**
 * Mobile Navigation Container
 * @param {*} props
 */
const MobileNavigationContainer = (props: MobileNavigationContainerType) => {
  const [history, setHistory] = useState<ElementType[]>([MobileNavigationMainView]);
  const Component = history[history.length - 1];

  const handleNavigateForward = (view: ElementType) => {
    setHistory([...history, view]);
  };

  const handleNavigateBack = () => {
    history.pop();
    setHistory([...history]);
  };

  const handleCloseNavigation = (e: MouseEvent<HTMLDivElement>) => {
    const anchorTarget = e.target as HTMLAnchorElement;
    if (anchorTarget?.href) {
      props.onClose();
    }
  };

  return (
    <div className="sw-mobile-nav" onClick={handleCloseNavigation}>
      <Component {...props} onForward={handleNavigateForward} onBack={handleNavigateBack} />
    </div>
  );
};

/**
 * Main Mobile Navigation View
 * @param {*} props
 */
const MobileNavigationMainView = ({
  userProfile,
  inbox = [],
  isPurchaseOrdersEnabled,
  isLoadBoardEnabled,
  isAnalyticsEnabled,
  hasOrdersViewPermission,
  hasAnalyticsViewPermission,
  hasDispatchViewPermission,
  isBidManagerEnabled,
  isPricingIntelEnabled,
  isAlertsDashboardEnabled,
  isCarrier,
  isSupplierUser,
  hasAppointmentsViewPermission,
  hasSchedulingViewPermission,
  onShowTasks,
  onForward
}: MobileNavigationContainerType & {
  isCarrier: boolean;
  onShowTasks: () => void;
  onForward: (view: ElementType) => void;
}) => {
  const unreadNotificationsCount = inbox.filter((message) => !message?.read).length;

  return (
    <>
      <div className="sw-mobile-nav-header" onClick={() => onForward(MobileNavigationProfileSubMenu)}>
        <i className="sw-avatar" style={{backgroundImage: `url(${userProfile.avatar || ''})`}} />
        <div className="sw-mobile-nav-profile w-full">
          <strong className="sw-mobile-nav-profile-name w-[156px] overflow-hidden text-ellipsis">
            Hi, {userProfile.first_name} {userProfile.last_name}
          </strong>
          <div className="w-[156px] overflow-hidden text-ellipsis">{userProfile.email}</div>
        </div>
        <i className="material-icons">navigate_next</i>
      </div>
      <div className="sw-mobile-nav-notifications">
        <div onClick={() => onForward(MobileNavigationNotificationSubMenu)} className="grey-border">
          <SvgIcon name="AlertFilled" color="#58595b" />
          <span className={classNames('uppercase', {['text-sw-error']: !!unreadNotificationsCount})}>
            {unreadNotificationsCount > 0 ? `${unreadNotificationsCount} Unread` : 'Notifications'}
          </span>
        </div>
        <div onClick={onShowTasks} className="grey-border">
          <div className="tasks-link">
            <SvgIcon name="ViewList" color="white" />
          </div>
          <span className="uppercase">Tasks</span>
        </div>
        {!userProfile.is_quoting_limited_user && !isSupplierUser && (
          <div onClick={() => onForward(MobileNavigationNewSubMenu)} className="grey-border bg-[#0A6FDB1A]">
            <SvgIcon name="AddCircleOutlined" color="well-water" />
            <span className="new-label uppercase">New</span>
          </div>
        )}
      </div>
      <div className="sw-mobile-nav-menu">
        {isAlertsDashboardEnabled && (
          <Link to="/compass" className="menu-title" activeClassName="active">
            Compass
          </Link>
        )}
        {!isSupplierUser ? (
          <Link to="/dashboard" className="menu-title" activeClassName="active">
            Shipments
          </Link>
        ) : null}
        {isSupplierUser || (isPurchaseOrdersEnabled && hasOrdersViewPermission) ? (
          <Link to="/purchase-orders" className="menu-title" activeClassName="active">
            Orders
          </Link>
        ) : null}
        {(!isSupplierUser && (hasSchedulingViewPermission || hasAppointmentsViewPermission)) || isCarrier ? (
          <ConditionalMobileNavigationSchedulingSubMenu onForward={onForward} />
        ) : null}
        <ConditionalSettlementsNavigationLink />
        {isLoadBoardEnabled && (
          <Link to="/load-board" className="menu-title" activeClassName="active">
            Load Board
          </Link>
        )}
        {isCarrier ? <RfpCarrierLink /> : null}
        {isAnalyticsEnabled && hasAnalyticsViewPermission && (
          <Link to="/analytics" className="menu-title" activeClassName="active">
            Analytics
          </Link>
        )}
        {(isBidManagerEnabled || isPricingIntelEnabled) && (
          <span className="menu-toggle-title menu-title" onClick={() => onForward(MobileNavigationToolsSubMenu)}>
            Tools <i className="material-icons">navigate_next</i>
          </span>
        )}
        {hasDispatchViewPermission && (
          <Link to="/dispatch" className="menu-title" activeClassName="active">
            Dispatch
          </Link>
        )}
        {!isSupplierUser && !userProfile.is_quoting_limited_user && (
          <span className="menu-toggle-title menu-title" onClick={() => onForward(MobileNavigationManageSubMenu)}>
            Manage <i className="material-icons">navigate_next</i>
          </span>
        )}
      </div>
    </>
  );
};

/**
 * Mobile Navigation - Profile Sub Menu
 * @param {Function} onBack
 */
const MobileNavigationProfileSubMenu = ({
  userProfile,
  onShowTerms,
  onShowPrivacyPolicy,
  onBack
}: {
  userProfile: User;
  onShowTerms: () => void;
  onShowPrivacyPolicy: () => void;
  onBack: () => void;
}) => (
  <div className="sw-mobile-sub-nav-menu">
    <div className="mobile-sub-nav-header">
      <i className="material-icons" onClick={onBack}>
        navigate_before
      </i>
      <h3>
        {userProfile.first_name} {userProfile.last_name}
      </h3>
      <i></i>
    </div>
    <div className="sw-mobile-nav-menu">
      <MenuToggle name="Profile & Preferences">
        <Link to="/user/profile" activeClassName="active">
          Profile
        </Link>
        <Link to="/user/notifications" activeClassName="active">
          Notifications
        </Link>
        <Link to="/user/user-defaults" activeClassName="active">
          User Defaults
        </Link>
        <Link to="/user/reset-password" activeClassName="active">
          Password
        </Link>
      </MenuToggle>
      <TenantPickerMobileMenuItem />
      <a
        href={shipwellReleaseNotesLink}
        target="_blank"
        title="View the Platform Release Notes"
        rel="noopener noreferrer"
      >
        <span> Release Notes</span>
      </a>
      <a role="button" onClick={onShowPrivacyPolicy}>
        Privacy Policy
      </a>
      <a role="button" onClick={onShowTerms}>
        Terms &amp; Conditions
      </a>
      <Link to="/signout">Sign Out</Link>
    </div>
  </div>
);

/**
 * Mobile Navigation - Manage Sub Menu
 * @param {Function} onBack
 */
const MobileNavigationManageSubMenu = (props: MobileNavigationContainerType & {onBack: () => void}) => {
  const {pdfGeneratorApiForDocumentGeneration} = useFlags();
  const hasManageCompanyProfilePermission =
    props.hasCompanyUserViewPermission ||
    props.hasCompanyAdministratorPermission ||
    props.hasCompanyDetailsUpdatePermission;
  const hasManageUsersPermission =
    props.hasCompanyUserViewPermission ||
    props.hasCompanyUserUpdatePermission ||
    props.hasCompanyUserCreatePermission ||
    props.hasCompanyUserDeletePermission;
  const hasManageContractsPermission =
    props.hasContractsViewPermission ||
    props.hasContractsUpdatePermission ||
    props.hasContractsDeletePermission ||
    props.hasContractsCreatePermission;
  const hasManageIntegrationsPermission =
    props.hasIntegrationsViewPermission ||
    props.hasIntegrationsCreatePermission ||
    props.hasIntegrationsManagePermission ||
    props.hasIntegrationsRemovePermission;
  const hasCompanyMenuToggleAccess =
    hasManageCompanyProfilePermission ||
    //contract and user management are located in the company management page
    hasManageContractsPermission ||
    hasManageUsersPermission ||
    hasManageIntegrationsPermission;
  return (
    <div className="sw-mobile-sub-nav-menu">
      <div className="mobile-sub-nav-header">
        <i className="material-icons" onClick={props.onBack}>
          navigate_before
        </i>
        <h3>Manage</h3>
        <i></i>
      </div>
      <div className="sw-mobile-nav-menu">
        {props.hasCarrierViewPermission && (
          <Link to="/carriers" activeClassName="active">
            Carriers
          </Link>
        )}
        {hasCompanyMenuToggleAccess ? (
          <MenuToggle name="Company">
            {hasManageCompanyProfilePermission ? (
              <Link to="/company/profile" activeClassName="active">
                Profile
              </Link>
            ) : null}
            {pdfGeneratorApiForDocumentGeneration ? (
              <Link to="/company/templates" activeClassName="active">
                Templates
              </Link>
            ) : null}
            <Link to="/company/custom-data" activeClassName="active">
              Custom Data
            </Link>
            {hasManageUsersPermission ? (
              <Link to="/company/users" activeClassName="active">
                Users
              </Link>
            ) : null}
            <Link to="/company/carrier-instructions" activeClassName="active">
              Carrier Instructions
            </Link>
            {hasManageIntegrationsPermission ? (
              <Link to="/company/integrations" activeClassName="active">
                Integrations
              </Link>
            ) : null}

            <MenuToggle name="Lane Management">
              <ConditionalRfpLink />
              {hasManageContractsPermission ? (
                <>
                  <Link className="subLink" to="/company/lane-management/contracts" activeClassName="active">
                    Contracts
                  </Link>
                  <Link
                    className="subLink"
                    to="/company/lane-management/service-level-agreements"
                    activeClassName="active"
                  >
                    SLAs
                  </Link>
                </>
              ) : null}
              <ConditionalAccessorialRatesLink />
              <ConditionalFuelSurchargesLink />
            </MenuToggle>

            <MenuToggle name="Company Defaults">
              <ConditionalCompassAlertsLink />
              <Link to={companyContractDefaultsPath} className="subLink" activeClassName="active">
                Contracts
              </Link>
              <Link to={companyRoutingGuideDefaultsPath} className="subLink" activeClassName="active">
                Routing Guides
              </Link>
              <ConditionalCarrierComplianceLink />
              <ConditionalCustomerSettingsLink />
              <ConditionalLoadOptimizationLink />
              <ConditionalSettlementsConfigsLink />
            </MenuToggle>
          </MenuToggle>
        ) : null}
        {props.hasCustomerViewPermission ? (
          <Link to="/customers" activeClassName="active">
            Customers
          </Link>
        ) : null}

        {!props.userProfile.is_quoting_limited_user ? (
          <PermissionsFallback permissions={[VIEW_ADDRESS_BOOK_PERMISSION]}>
            <Link to="/addressbook" activeClassName="active">
              Address Book
            </Link>
          </PermissionsFallback>
        ) : null}
        {!props.userProfile.is_quoting_limited_user ? (
          <Link to="/products" activeClassName="active">
            Products
          </Link>
        ) : null}
        <ConditionalServiceProvidersLink />
        {props.hasDispatchViewPermission ? (
          <Link to="/drivers" activeClassName="active">
            Drivers
          </Link>
        ) : null}
        {props.hasDispatchViewPermission ? (
          <MenuToggle name="Equipment">
            <Link to="/equipment/power-units" activeClassName="active">
              Power Units
            </Link>
            <Link to="/equipment/trailers" activeClassName="active">
              Trailers
            </Link>
          </MenuToggle>
        ) : null}
        {props.isRoutingGuidesEnabled ||
          (props.isOrderConsolidationWorkflowsEnabled ? (
            <MenuToggle name="Workflows">
              {props.isRoutingGuidesEnabled ? (
                <Link to="/automation/routing-guides" activeClassName="active">
                  Routing Guides
                </Link>
              ) : null}
            </MenuToggle>
          ) : null)}
        {props.isSupplierInvitesEnabled ? (
          <Link to="/supplier-shipments/?onlyMyShipments=true" activeClassName="active">
            Shipment Requests
          </Link>
        ) : null}

        {!pdfGeneratorApiForDocumentGeneration ? (
          <MenuToggle name="Documents">
            <DocumentTypesLinks />
          </MenuToggle>
        ) : null}
      </div>
    </div>
  );
};

/**
 * Mobile Navigation - Tools Sub Menu
 * @param {Function} onBack
 */
const MobileNavigationToolsSubMenu = (props: MobileNavigationContainerType & {onBack: () => void}) => (
  <div className="sw-mobile-sub-nav-menu">
    <div className="mobile-sub-nav-header">
      <i className="material-icons" onClick={props.onBack}>
        navigate_before
      </i>
      <h3>Tools</h3>
      <i></i>
    </div>
    <div className="sw-mobile-nav-menu">
      {props.isBidManagerEnabled && (
        <Link to="/bid-manager" activeClassName="active">
          Bid Manager
        </Link>
      )}
      {props.isPricingIntelEnabled && (
        <Link to="/pricing-intel" activeClassName="active">
          Pricing Intel
        </Link>
      )}
    </div>
  </div>
);

/**
 * Mobile Navigation - "New" Sub Menu
 * @param {Function} onBack
 */
const MobileNavigationNewSubMenu = (props: MobileNavigationContainerType & {onBack: () => void}) => {
  const {airMode} = useFlags();

  return (
    <div className="sw-mobile-sub-nav-menu">
      <div className="mobile-sub-nav-header">
        <i className="material-icons" onClick={props.onBack}>
          navigate_before
        </i>
        <h3>New</h3>
        <i></i>
      </div>
      <div className="sw-mobile-nav-menu">
        <PermissionsWithQuotingLimitedUserFallback permissions={[CREATE_SHIPMENTS_USER_PERMISSION]}>
          <MenuToggle name="Shipment" defaultOpen>
            <Link to="/new-shipment-ftl" className="grey-color uppercase" activeClassName="active">
              Full Truckload
            </Link>
            <Link to="/new-shipment-ltl" className="grey-color uppercase" activeClassName="active">
              Less Than Truckload
            </Link>
            <Link to="/new-shipment-vltl" className="grey-color uppercase" activeClassName="active">
              Volume Less Than Truckload
            </Link>
            <ConditionalCreateDrayageShipmentLink />
            <Link to="/new-quote-parcel" className="grey-color uppercase" activeClassName="active">
              Parcel
            </Link>
            <ConditionalCreateIntermodalShipmentLink />
            <Link to="/new-shipment-rail" className="grey-color uppercase" activeClassName="active">
              Rail
            </Link>
            {airMode && (
              <Link className="grey-color uppercase" to="/shipments-v2/air/create" activeClassName="active">
                AIR
              </Link>
            )}
          </MenuToggle>
        </PermissionsWithQuotingLimitedUserFallback>
        <PermissionsWithQuotingLimitedUserFallback permissions={[CREATE_QUOTES_USER_PERMISSION]}>
          <Link to="/new-quote" className="menu-title" activeClassName="active">
            Quote
          </Link>
        </PermissionsWithQuotingLimitedUserFallback>
        {props.isPurchaseOrdersEnabled ? (
          <PermissionsFallback permissions={[CREATE_ORDERS_USER_PERMISSION]}>
            <MenuToggle name="Order">
              <ConditionalCreateOrderMobileNavigationLinks />
            </MenuToggle>
          </PermissionsFallback>
        ) : null}
        {props.hasCustomerViewPermission && (
          <Link to="/customers/create" className="menu-title" activeClassName="active">
            Customer
          </Link>
        )}
        {props.hasCarrierViewPermission && (
          <Link to="/carriers/search" className="menu-title" activeClassName="active">
            Carrier
          </Link>
        )}
        {!props.userProfile.is_quoting_limited_user && (
          <PermissionsFallback permissions={[VIEW_ADDRESS_BOOK_PERMISSION]}>
            <Link to="/addressbook/create" className="menu-title" activeClassName="active">
              Address Book
            </Link>
          </PermissionsFallback>
        )}
        {!props.userProfile.is_quoting_limited_user && (
          <Link to="/products/create" className="menu-title" activeClassName="active">
            Product
          </Link>
        )}
      </div>
    </div>
  );
};

/**
 * Mobile Navigation - Notifications
 * @param {Function} onBack
 */

const MobileNavigationNotificationSubMenu = ({
  router,
  onBack,
  inbox,
  handleScroll
}: {
  router: InjectedRouter;
  onBack: () => void;
  inbox: (InboxMessage | undefined)[];
  handleScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}) => (
  <div className="sw-mobile-sub-nav-menu">
    <div className="mobile-sub-nav-header">
      <i className="material-icons" onClick={onBack}>
        navigate_before
      </i>
      <h3>Notifications</h3>
      <i></i>
    </div>
    <Inbox router={router} inbox={inbox} handleScroll={handleScroll} />
  </div>
);
type MobileNavigationSchedulingSubMenuType = {
  onBack: () => void;
  hasAppointmentsViewPermission?: boolean;
  hasSchedulingViewPermission?: boolean;
};
/**
 * Mobile Navigation - Scheduling Sub Menu
 */
const MobileNavigationSchedulingSubMenu = (props: MobileNavigationSchedulingSubMenuType) => (
  <div className="sw-mobile-sub-nav-menu">
    <div className="mobile-sub-nav-header">
      <i className="material-icons" onClick={props.onBack}>
        navigate_before
      </i>
      <h3>Scheduling</h3>
      <i></i>
    </div>
    <div className="sw-mobile-nav-menu">
      {props.hasAppointmentsViewPermission ? (
        <Link to="/appointments" activeClassName="active">
          Appointments
        </Link>
      ) : null}
      {props.hasSchedulingViewPermission ? (
        <Link to="/facilities" activeClassName="active">
          My Facilities
        </Link>
      ) : null}
    </div>
  </div>
);

const ConditionalMobileNavigationSchedulingSubMenu = (props: {onForward: (element: ElementType) => void}) => {
  const {fiDockScheduling} = useFlags();
  const {data: auth, isLoading, isError} = useUserMe();

  const hasAppointmentsViewPermission = auth?.user?.permissions?.includes('dock_scheduling.view_appointments');
  const hasSchedulingViewPermission = auth?.user?.permissions?.includes('dock_scheduling.view_appointments');
  const isCarrier = !!auth?.company?.carrier && !auth?.company?.shipper;
  const isShipper = !!auth?.company?.shipper;

  const {
    appointments: {entries}
  } = useAppointments(
    {page: 1, pageSize: 1},
    {
      enabled: isCarrier // only need to fetch appointments to check if a carrier should see the "appointments" link.
    }
  ); // we only need to see if the user has any

  // the feature flag should be considered if you are not a carrier
  if ((!fiDockScheduling && !isCarrier) || isLoading || isError) return null;

  if (isCarrier) {
    return entries?.length ? (
      <Link to="/appointments" className="menu-title" activeClassName="active">
        Appointments
      </Link>
    ) : null;
  }

  if (!hasAppointmentsViewPermission && !hasSchedulingViewPermission) {
    return null;
  }

  if (isShipper) {
    return (
      <span className="menu-toggle-title menu-title" onClick={() => props.onForward(MobileNavigationSchedulingSubMenu)}>
        Scheduling <i className="material-icons">navigate_next</i>
      </span>
    );
  }

  return null;
};

const mapStateToProps = ({userProfile: {user}, company, userCompany}: State) => ({
  userProfile: user,
  hasCustomerViewPermission: user.permissions?.includes('customer_relationships.view') || false,
  hasShipmentUpdatePermission:
    user.permissions?.includes(UPDATE_SHIPMENTS_USER_PERMISSION) ||
    user.permissions?.includes(UPDATE_MY_SHIPMENTS_USER_PERMISSION) ||
    false,
  hasCompanyUserViewPermission: user.permissions?.includes('contracts.view_company_users') || false,
  hasCompanyUserCreatePermission: user.permissions?.includes('users.create_company_users') || false,
  hasCompanyUserDeletePermission: user.permissions?.includes('users.delete_company_users') || false,
  hasCompanyUserUpdatePermission: user.permissions?.includes('contracts.update_company_users') || false,
  hasCompanyDetailsViewPermission: user.permissions?.includes('company.view_company_details') || false,
  hasCompanyAdministratorPermission: user.permissions?.includes('company.administrator') || false,
  hasCompanyDetailsUpdatePermission: user.permissions?.includes('company.update_company_details') || false,
  hasCarrierViewPermission: user.permissions?.includes('carrier_relationships.view_carrier_relationships') || false,
  hasQuotesUpdatePermission: user.permissions?.includes('quotes.update_quotes') || false,
  hasDispatchViewPermission: user.permissions?.includes('carrier.view_driver_relationships') || false,
  hasAnalyticsViewPermission: user.permissions?.includes('analytics.view') || false,
  hasOrdersViewPermission: user.permissions?.includes('orders.view_orders') || false,
  isPurchaseOrdersEnabled: company.company.feature_flags?.purchase_orders_enabled || false,
  isAnalyticsEnabled: company.company.feature_flags?.analytics_enabled || false,
  isLoadBoardEnabled: company.company.feature_flags?.show_load_board || false,
  isContractsEnabled: company.company.feature_flags?.contracts_enabled || false,
  isSupplierInvitesEnabled: company.company.feature_flags?.supplier_invites_enabled || false,
  isRoutingGuidesEnabled: company.company.feature_flags?.policies_enabled || false,
  isBidManagerEnabled: userCompany.company.feature_flags?.bid_manager_enabled || false,
  isPricingIntelEnabled: userCompany.company.feature_flags?.historical_pricing_enabled || false,
  isAlertsDashboardEnabled: userCompany.company.feature_flags?.alerts_dashboard_enabled || false,
  isOrderConsolidationWorkflowsEnabled:
    userCompany.company.feature_flags?.order_consolidation_workflows_enabled || false,
  isCarrier: userCompany.company.carrier,
  hasContractsViewPermission: user.permissions?.includes('contracts.view_contracts') || false,
  hasContractsUpdatePermission: user.permissions?.includes('contracts.update_contracts') || false,
  hasContractsDeletePermission: user.permissions?.includes('contracts.delete_contracts') || false,
  hasContractsCreatePermission: user.permissions?.includes('contracts.create_contracts') || false,
  hasIntegrationsViewPermission: user.permissions?.includes('integrations.view_integrations') || false,
  hasIntegrationsCreatePermission: user.permissions?.includes('integrations.add_integrations') || false,
  hasIntegrationsRemovePermission: user.permissions?.includes('integrations.remove_integrations') || false,
  hasIntegrationsManagePermission: user.permissions?.includes('integrations.manage_integrations') || false,
  isSupplierUser: Boolean(userCompany.company.name === 'SYSTEM-WIDE SUPPLIER TENANT'),
  hasAppointmentsViewPermission: user.permissions?.includes('dock_scheduling.view_appointments') || false,
  hasSchedulingViewPermission: user.permissions?.includes('dock_scheduling.view_facilities') || false
});

export default connect(mapStateToProps)(MobileNavigationContainer);
