/* eslint-disable react/prop-types */
/* eslint-disable no-class-assign */
import omit from 'lodash/omit';
import {Component} from 'react';
import {connect} from 'react-redux';
import {reduxForm, change, formValueSelector, SubmissionError} from 'redux-form';
import {compose, renameProp} from 'recompose';
import {CustomFieldEntityTypesEnum, ChargeCategory} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Col, Form} from 'react-bootstrap';
import {Modal} from '@shipwell/shipwell-ui';
import {FinancialsExpandedForm} from './FinancialsExpandedForm';
import {FinancialsExpandedFormFooter} from './FinancialsExpandedFormFooter';
import {NewFinancialsExpandedForm} from './NewFinancialsExpandedForm';
import {NewFinancialsExpandedFormFooter} from './NewFinancialsExpandedFormFooter';
import {
  CustomerTable,
  VendorTable,
  CustomerTableHeader,
  VendorTableHeader,
  NewCustomerTable,
  NewVendorTable,
  NewCustomerTableHeader,
  NewVendorTableHeader
} from './financialsTables';
import {getCalculatedAggregateTotals} from './getCalculatedAggregateTotals';
import {bind} from 'App/utils/camelize';
import * as actions from 'App/actions/shipments';
import * as shipmentDetailsActions from 'App/actions/shipmentdetails';
import RateTableDetailsContainer from 'App/containers/userCompany/rateTables/components/rateTableDetailsContainer';
import {unpackErrors} from 'App/utils/globals';
import validate from 'App/utils/validateFinancialsExpandedForm';
import {FREIGHT_INVOICES_QUERY_KEY, LEGACY_SHIPMENTS_QUERY_KEY} from 'App/data-hooks/queryKeys';
import {withCustomFieldsProvider} from 'App/data-hooks';
import '../_financials.scss';
import withFlags from 'App/utils/withFlags';
import {storeRecentChargeCodes} from 'App/containers/Book/FormSections/Financials/components/utils';

@connect(
  (state) => ({
    user: state.auth.user,
    is_quoting_limited_user: state.auth.is_quoting_limited_user,
    company: state.auth.company,
    chargeCategories: state.shipments.chargeCategories,
    shipmentFinancialsForm: state.form.shipmentFinancialsForm,
    vendorAccRateTable: state.shipmentdetails.one?.metadata?.vendor_side_accessorial_charge_table,
    vendorFscRateTable: state.shipmentdetails.one?.metadata?.vendor_side_fuel_surcharge_table,
    customerAccRateTable: state.shipmentdetails.one?.metadata?.customer_side_accessorial_charge_table,
    customerFscRateTable: state.shipmentdetails.one?.metadata?.customer_side_fuel_surcharge_table,
    one: state.shipmentdetails.one,
    customerSide: state.shipmentdetails.one,
    vendorSide: state.shipmentdetails.one
  }),
  {...actions, ...shipmentDetailsActions, changeField: change}
)
export class FinancialsExpanded extends Component {
  constructor(props) {
    super(props);

    bind(this, [
      'editCustomerFinancials',
      'editVendorFinancials',
      'handleCancel',
      'onFinancialsSubmit',
      'editFinancials',
      'copyVendorFinancials',
      'copyCustomerFinancials',
      'calculateGrandTotal',
      'calculateAggregateTotals'
    ]);

    this.state = {
      whichFinancials: null,
      showCustomerModal: false,
      showVendorModal: false
    };
  }

  componentDidMount() {
    if (this.props.customerAssignment || this.props.vendorAssignment) {
      this.calculateAggregateTotals(
        {
          customerAssignment: this.props.customerAssignment,
          vendorAssignment: this.props.vendorAssignment
        },
        this.props.shipmentChargeLineItemCustomFields
      );
    }
    this.props.triggerEditVendorFinancials(this.editVendorFinancials);
    this.props.triggerEditCustomerFinancials(this.editCustomerFinancials);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.customerAssignment !== this.props.customerAssignment ||
      nextProps.vendorAssignment !== this.props.vendorAssignment ||
      nextProps.shipmentChargeLineItemCustomFields !== this.props.shipmentChargeLineItemCustomFields
    ) {
      this.calculateAggregateTotals(
        {
          customerAssignment: nextProps.customerAssignment,
          vendorAssignment: nextProps.vendorAssignment
        },
        nextProps.shipmentChargeLineItemCustomFields
      );
    }
    if (nextProps.vendorAssignment !== this.props.vendorAssignment) {
      //make sure the form is closed and refreshed before allowing editing
      this.props.setEditVendorFinancials(false);
    }
    //check if financial line items have changed, default descriptions when category is selected;
    if (this.props.shipmentFinancialsForm?.values?.financials && nextProps.shipmentFinancialsForm?.values?.financials) {
      const oldValues = this.props.shipmentFinancialsForm.values?.financials;
      const newValues = nextProps.shipmentFinancialsForm.values?.financials;

      for (let i = 0; i < newValues.length; i++) {
        if (
          newValues[i].category &&
          (!oldValues[i] ||
            (oldValues[i]?.category && newValues[i]?.category !== oldValues[i].category) ||
            !oldValues[i]?.category)
        ) {
          if (
            !newValues[i].unit_name ||
            nextProps.chargeCategories.filter((c) => c.name === newValues[i].unit_name).length > 0
          ) {
            const description = nextProps.chargeCategories.filter((c) => c.id === newValues[i].category)[0]?.name || '';
            this.props.dispatch(change('shipmentFinancialsForm', 'financials[' + i + '].unit_name', description));
          }
        }
      }
    }
  }

  onFinancialsSubmit(attrs) {
    const chargeCodes = attrs.financials.map((financial) => financial.charge_code);
    const {one} = this.props;
    if (this.state.whichFinancials === 'customer') {
      //update the customer assignment
      let currentCustomerAssignment = {};
      if (this.props.customerAssignment) {
        currentCustomerAssignment = this.props.customerAssignment;
      }
      currentCustomerAssignment.vendor_charge_line_items = attrs.financials;
      // ensure currency matches the shipment
      currentCustomerAssignment.vendor_charge_line_items?.forEach((charge) => {
        charge.unit_amount_currency = one.preferred_currency;
      });
      if (currentCustomerAssignment.id) {
        //do a PUT on the vendor assignment
        return this.props
          .editCarrierAssignment(this.props.one.id, currentCustomerAssignment.id, currentCustomerAssignment)
          .then((response) => {
            if (response.status === 200) {
              storeRecentChargeCodes(chargeCodes, 'customer');
              this.props.clearRateTableDetails();
              this.handleCancel();
              this.props.updated();
              this.calculateAggregateTotals({
                customerAssignment: response.details,
                vendorAssignment: this.props.vendorAssignment
              });
              this.props.queryClient.invalidateQueries([FREIGHT_INVOICES_QUERY_KEY, this.props.one.id]);
              this.props.queryClient.invalidateQueries([LEGACY_SHIPMENTS_QUERY_KEY, this.props.one.id]);
            } else {
              const errors = response.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError, [
                'vendor_charge_line_items',
                'customer_charge_line_items'
              ]);
              if (submissionError.customer_charge_line_items) {
                submissionError.financials = submissionError.customer_charge_line_items;
              }
              if (submissionError.vendor_charge_line_items) {
                submissionError.financials = submissionError.vendor_charge_line_items;
              }
              submissionError._error = response.error_description;
              throw new SubmissionError(submissionError);
            }
          });
      }
      //POST is not an option for customer assignment
      this.props.updated();
      this.handleCancel();
    } else {
      //update the vendor assignment
      let currentVendorAssignment = {};
      if (this.props.vendorAssignment) {
        currentVendorAssignment = this.props.vendorAssignment;
      }
      currentVendorAssignment.customer_charge_line_items = attrs.financials;
      // ensure currency matches the shipment
      currentVendorAssignment.customer_charge_line_items?.forEach((charge) => {
        charge.unit_amount_currency = one.preferred_currency;
      });
      if (currentVendorAssignment.id) {
        const payload = {
          ...currentVendorAssignment,
          vendor: {
            ...currentVendorAssignment.vendor,
            brokerage: omit(currentVendorAssignment.vendor.brokerage, ['billing_address', 'mailing_address'])
          }
        };
        return this.props
          .editCarrierAssignment(this.props.one.id, currentVendorAssignment.id, payload)
          .then((response) => {
            if (response.status === 200) {
              storeRecentChargeCodes(chargeCodes, 'vendor');
              this.props.clearRateTableDetails();
              this.handleCancel();
              this.props.updated();
              this.calculateAggregateTotals({
                vendorAssignment: response.details,
                customerAssignment: this.props.customerAssignment
              });
              this.props.queryClient.invalidateQueries([FREIGHT_INVOICES_QUERY_KEY, this.props.one.id]);
              this.props.queryClient.invalidateQueries([LEGACY_SHIPMENTS_QUERY_KEY, this.props.one.id]);
            } else {
              const errors = response.field_errors || [];
              let submissionError = {};
              submissionError = unpackErrors(errors, submissionError, [
                'vendor_charge_line_items',
                'customer_charge_line_items'
              ]);
              if (submissionError.customer_charge_line_items) {
                submissionError.financials = submissionError.customer_charge_line_items;
              }
              if (submissionError.vendor_charge_line_items) {
                submissionError.financials = submissionError.vendor_charge_line_items;
              }
              submissionError._error = response.error_description;
              throw new SubmissionError(submissionError);
            }
          });
      }
      this.handleCancel();
      //we cant create financials until there is a vendor assigned, so there should be no POST here
      this.props.updated();
    }
  }

  calculateAggregateTotals(assignments, shipmentChargeLineItemCustomFields) {
    const {customerAssignment, vendorAssignment} = assignments;

    const {
      aggregateVendorCharges,
      aggregatePrepaidCharges,
      aggregateCustomerCharges,
      myVendorCharges,
      theirVendorCharges,
      myCustomerCharges,
      theirCustomerCharges
    } = getCalculatedAggregateTotals({
      customerAssignment,
      vendorAssignment,
      customFields: shipmentChargeLineItemCustomFields
    });

    // I realize this could be passed as `this.setState(getCalculatedAggregateTotals(assignments))`, but until this file
    // gets a major rewrite, I'd rather it be clear what values are being put into state here.
    this.setState({
      aggregateVendorCharges,
      aggregatePrepaidCharges,
      aggregateCustomerCharges,
      myVendorCharges,
      theirVendorCharges,
      myCustomerCharges,
      theirCustomerCharges
    });
  }

  editFinancials(which) {
    this.setState({
      whichFinancials: which
    });
  }

  editCustomerFinancials(addNew) {
    if (this.props.editCustomerFinancials === true) {
      this.props.selectFinancials(null);
      this.props.setEditVendorFinancials(false);
      this.props.setEditCustomerFinancials(false);
    } else {
      this.setState({showCustomerModal: true});
      let charges = !this.props.shipmentFinancialUpdate24
        ? [{unit_quantity: 1, category: ChargeCategory.Lh, unit_name: 'Line Haul', charge_code: 'LHS'}]
        : [{unit_quantity: 1}];
      if (this.state.myCustomerCharges && this.state.myCustomerCharges.length > 0) {
        charges = [...this.state.myCustomerCharges, ...(addNew ? [...charges] : [])];
      }
      this.props.selectFinancials(JSON.parse(JSON.stringify(charges)));
      this.editFinancials('customer');
      this.props.setEditVendorFinancials(false);
      this.props.setEditCustomerFinancials(!this.props.editCustomerFinancials);
    }
  }

  editVendorFinancials(addNew = false) {
    if (this.props.editVendorFinancials === true) {
      this.props.selectFinancials(null);
      this.props.setEditVendorFinancials(false);
      this.props.setEditCustomerFinancials(false);
    } else {
      this.setState({showVendorModal: true});
      let charges = !this.props.shipmentFinancialUpdate24
        ? [{unit_quantity: 1, category: ChargeCategory.Lh, unit_name: 'Line Haul', charge_code: 'LHS'}]
        : [{unit_quantity: 1}];
      if (this.state.myVendorCharges && this.state.myVendorCharges.length > 0) {
        charges = [...this.state.myVendorCharges, ...(addNew ? [...charges] : [])];
      }
      this.props.selectFinancials(JSON.parse(JSON.stringify(charges)));
      this.editFinancials('carrier');
      this.props.setEditVendorFinancials(!this.props.editVendorFinancials);
      this.props.setEditCustomerFinancials(false);
    }
  }

  handleCancel() {
    this.props.selectFinancials(null);
    this.props.setEditVendorFinancials(false);
    this.props.setEditCustomerFinancials(false);
    this.setState({showCustomerModal: false});
    this.setState({showVendorModal: false});
  }

  copyCustomerFinancials() {
    const customerCharges = this.state.theirCustomerCharges;
    if (customerCharges && customerCharges.length > 0) {
      for (let i = 0; i < customerCharges.length; i++) {
        if (customerCharges[i].id) {
          delete customerCharges[i].id;
        }
      }
    }
    this.props.selectFinancials(JSON.parse(JSON.stringify(customerCharges)));
    this.editFinancials('customer');
    this.props.setEditVendorFinancials(false);
    this.props.setEditCustomerFinancials(!this.props.editCustomerFinancials);
  }

  copyVendorFinancials() {
    const vendorCharges = this.state.theirVendorCharges;
    if (vendorCharges && vendorCharges.length > 0) {
      for (let i = 0; i < vendorCharges.length; i++) {
        if (vendorCharges[i].id) {
          delete vendorCharges[i].id;
        }
      }
    }
    this.props.selectFinancials(JSON.parse(JSON.stringify(vendorCharges)));
    this.editFinancials('vendor');
    this.props.setEditVendorFinancials(!this.props.editVendorFinancials);
    this.props.setEditCustomerFinancials(false);
  }

  calculateGrandTotal(charges = []) {
    return charges.reduce((total, charge) => {
      return total + charge.unit_amount * charge.unit_quantity;
    }, 0);
  }

  render() {
    const {
      vendorAssignment,
      customerAssignment,
      error,
      handleSubmit,
      submitting,
      currentTotal,
      currentPrepaidTotal,
      prepaidTotals,
      one,
      type,
      canEdit,
      is_quoting_limited_user,
      shipmentChargeLineItemCustomFields,
      shipmentFinancialUpdate24
    } = this.props;
    const {aggregateVendorCharges, aggregateCustomerCharges, aggregatePrepaidCharges} = this.state;
    const shipmentCurrency = one.preferred_currency;
    const className = this.props.className || null;

    // If you are the customer on the shipment, don't bother showing the customer financials column
    const showCustomerColumn = !(
      customerAssignment &&
      customerAssignment.customer &&
      customerAssignment.customer.id === this.props.company.id
    );

    const grandTotals = {};
    let prepaidTotal = 0;

    if (this.props.editVendorFinancials) {
      //currently editing vendor, so grab that total from the form
      prepaidTotal = currentPrepaidTotal;
      grandTotals.vendor = currentTotal;
      if (this.state.myCustomerCharges) {
        grandTotals.customer = this.calculateGrandTotal(this.state.myCustomerCharges);
      }
    } else if (this.props.editCustomerFinancials) {
      //currently editing customer, so grab that total from the form
      grandTotals.customer = currentTotal;
      if (this.state.myVendorCharges) {
        prepaidTotal = this.state.myVendorCharges.reduce((acc, cur) => {
          return acc + parseFloat(cur.prepaid_amount);
        }, 0);
        grandTotals.vendor = this.calculateGrandTotal(this.state.myVendorCharges);
      } else {
        grandTotals.vendor = 0;
        prepaidTotal = 0;
      }
    } else if (this.state.myCustomerCharges && this.state.myVendorCharges) {
      prepaidTotal = this.state.myVendorCharges.reduce((acc, cur) => {
        return acc + parseFloat(cur.prepaid_amount);
      }, 0);
      grandTotals.customer = this.calculateGrandTotal(this.state.myCustomerCharges);
      grandTotals.vendor = this.calculateGrandTotal(this.state.myVendorCharges);
    }
    if (!isNaN(grandTotals.customer) && !isNaN(grandTotals.vendor) && grandTotals.vendor !== 0) {
      grandTotals.customerMarkup = parseFloat(grandTotals.customer - grandTotals.vendor);
      grandTotals.customerMargin = (parseFloat(grandTotals.customer / grandTotals.vendor) - 1) * 100;

      if (isNaN(grandTotals.customerMarkup)) {
        grandTotals.customerMarkup = 0;
        grandTotals.customerMargin = 0;
      }
    }

    return (
      <div className={`${className ? className : ''}`}>
        <div className="flex">
          <Col xs={12} className="m-0 w-full p-0">
            {type === 'invoicing' ? (
              <div className="financialsExpanded__customer m-0 p-0">
                {shipmentFinancialUpdate24 ? (
                  <>
                    {this.state.theirCustomerCharges || this.state.myCustomerCharges ? (
                      <>
                        <NewCustomerTableHeader
                          onCopyClick={() => this.copyCustomerFinancials()}
                          isEditable={canEdit}
                          isEditing={this.props.editCustomerFinancials}
                          showCustomerColumn={showCustomerColumn}
                          customerName={customerAssignment?.customer?.name}
                          vendorName={customerAssignment?.vendor?.name}
                          theirCustomerCharges={this.state.theirCustomerCharges}
                          customFields={shipmentChargeLineItemCustomFields}
                        />
                        <NewCustomerTable
                          isEditable={canEdit}
                          customerAssignment={customerAssignment}
                          aggregateCustomerCharges={aggregateCustomerCharges}
                          theirCustomerCharges={this.state.theirCustomerCharges}
                          myCustomerCharges={this.state.myCustomerCharges}
                          showCustomerColumn={showCustomerColumn}
                          grandTotals={grandTotals}
                          shipmentCurrency={shipmentCurrency}
                          customFields={shipmentChargeLineItemCustomFields}
                          onEditClick={() => this.editCustomerFinancials(true)}
                        />
                      </>
                    ) : (
                      <button onClick={() => this.editCustomerFinancials()}>Add Item +</button>
                    )}
                    <Modal
                      show={this.state.showCustomerModal || this.props.editCustomerFinancials}
                      onClose={() => {
                        this.handleCancel();
                      }}
                      footerComponent={null}
                      title="Customer Financial"
                      size="large"
                    >
                      <Form onSubmit={handleSubmit(this.onFinancialsSubmit)}>
                        <NewFinancialsExpandedForm
                          changeField={this.props.changeField}
                          lineItemTotals={this.props.financialTotals}
                          prepaidTotal={prepaidTotal}
                          grandTotals={grandTotals}
                          mode="customer"
                          shipmentCurrency={shipmentCurrency}
                          chargeCodesValues={this.props.shipmentFinancialsForm?.values?.financials}
                          form="shipmentFinancialsForm"
                        />
                        <NewFinancialsExpandedFormFooter
                          onCancel={this.handleCancel}
                          isSubmitting={submitting}
                          error={error}
                        />
                      </Form>
                    </Modal>
                  </>
                ) : (
                  <>
                    <CustomerTableHeader
                      onEditClick={() => this.editCustomerFinancials()}
                      onCopyClick={() => this.copyCustomerFinancials()}
                      isEditable={canEdit}
                      isEditing={this.props.editCustomerFinancials}
                      showCustomerColumn={showCustomerColumn}
                      customerName={customerAssignment?.customer?.name}
                      venderName={customerAssignment?.vendor?.name}
                      theirCustomerCharges={this.state.theirCustomerCharges}
                      customFields={shipmentChargeLineItemCustomFields}
                    />

                    {this.props.editCustomerFinancials ? (
                      <Form onSubmit={handleSubmit(this.onFinancialsSubmit)}>
                        <FinancialsExpandedForm
                          lineItemTotals={this.props.financialTotals}
                          prepaidTotal={prepaidTotal}
                          grandTotals={grandTotals}
                          mode="customer"
                          shipmentCurrency={shipmentCurrency}
                        />
                        <FinancialsExpandedFormFooter
                          onCancel={this.handleCancel}
                          isSubmitting={submitting}
                          error={error}
                        />
                      </Form>
                    ) : (
                      <CustomerTable
                        customerAssignment={customerAssignment}
                        aggregateCustomerCharges={aggregateCustomerCharges}
                        theirCustomerCharges={this.state.theirCustomerCharges}
                        myCustomerCharges={this.state.myCustomerCharges}
                        showCustomerColumn={showCustomerColumn}
                        grandTotals={grandTotals}
                        shipmentCurrency={shipmentCurrency}
                        customFields={shipmentChargeLineItemCustomFields}
                      />
                    )}
                  </>
                )}

                {this.props.customerFscRateTable || this.props.customerAccRateTable ? (
                  <RateTableDetailsContainer
                    onRateTableClick={() => {
                      if (customerAssignment && !this.props.editCustomerFinancials) {
                        this.editCustomerFinancials();
                      }
                    }}
                    financialSide="customer"
                    isEditMode={canEdit && !is_quoting_limited_user && customerAssignment}
                  />
                ) : null}
              </div>
            ) : type === 'billing' ? (
              !is_quoting_limited_user && (
                <div className="financialsExpanded__vendor m-0 p-0">
                  {shipmentFinancialUpdate24 ? (
                    <>
                      {this.state.theirCustomerCharges || this.state.myCustomerCharges ? (
                        <>
                          <NewVendorTableHeader
                            isEditable={canEdit}
                            onCopyClick={this.copyVendorFinancials}
                            vendorAssignment={vendorAssignment}
                            is_quoting_limited_user={is_quoting_limited_user}
                            theirVendorCharges={this.state.theirVendorCharges}
                            myVendorCharges={this.props.myVendorCharges}
                            companyName={this.props.company.name}
                            customFields={shipmentChargeLineItemCustomFields}
                          />
                          <NewVendorTable
                            isEditable={canEdit}
                            is_quoting_limited_user={is_quoting_limited_user}
                            vendorAssignment={vendorAssignment}
                            aggregateVendorCharges={aggregateVendorCharges}
                            aggregatePrepaidCharges={aggregatePrepaidCharges}
                            prepaidTotal={prepaidTotal}
                            theirVendorCharges={this.state.theirVendorCharges}
                            myVendorCharges={this.state.myVendorCharges}
                            shipmentCurrency={shipmentCurrency}
                            grandTotals={grandTotals}
                            customFields={shipmentChargeLineItemCustomFields}
                            onEditClick={() => (vendorAssignment ? this.editVendorFinancials(true) : null)}
                          />
                        </>
                      ) : (
                        <button onClick={() => this.editVendorFinancials()}>Add Item +</button>
                      )}
                      <Modal
                        show={this.state.showVendorModal || this.props.editVendorFinancials}
                        onClose={() => {
                          this.handleCancel();
                        }}
                        footerComponent={null}
                        title="Carrier Financial"
                        size="large"
                      >
                        <Form onSubmit={handleSubmit(this.onFinancialsSubmit)}>
                          <NewFinancialsExpandedForm
                            changeField={this.props.changeField}
                            lineItemTotals={this.props.financialTotals}
                            prepaidTotals={prepaidTotals}
                            prepaidTotal={prepaidTotal}
                            grandTotals={grandTotals}
                            mode="vendor"
                            shipmentCurrency={shipmentCurrency}
                            chargeCodesValues={this.props.shipmentFinancialsForm?.values?.financials}
                            form="shipmentFinancialsForm"
                          />
                          <NewFinancialsExpandedFormFooter
                            onCancel={this.handleCancel}
                            isSubmitting={submitting}
                            error={error}
                          />
                        </Form>
                      </Modal>
                    </>
                  ) : (
                    <>
                      <VendorTableHeader
                        isEditable={canEdit}
                        isEditing={this.props.editVendorFinancials}
                        onEditClick={() => (vendorAssignment ? this.editVendorFinancials() : null)}
                        onCopyClick={this.copyVendorFinancials}
                        vendorAssignment={vendorAssignment}
                        is_quoting_limited_user={is_quoting_limited_user}
                        theirVendorCharges={this.state.theirVendorCharges}
                        myVendorCharges={this.props.myVendorCharges}
                        companyName={this.props.company.name}
                        customFields={shipmentChargeLineItemCustomFields}
                      />
                      {this.props.editVendorFinancials ? (
                        <Form onSubmit={handleSubmit(this.onFinancialsSubmit)}>
                          <FinancialsExpandedForm
                            lineItemTotals={this.props.financialTotals}
                            prepaidTotals={prepaidTotals}
                            prepaidTotal={prepaidTotal}
                            grandTotals={grandTotals}
                            mode="vendor"
                            shipmentCurrency={shipmentCurrency}
                          />
                          <FinancialsExpandedFormFooter
                            onCancel={this.handleCancel}
                            isSubmitting={submitting}
                            error={error}
                          />
                        </Form>
                      ) : (
                        <VendorTable
                          vendorAssignment={vendorAssignment}
                          aggregateVendorCharges={aggregateVendorCharges}
                          aggregatePrepaidCharges={aggregatePrepaidCharges}
                          prepaidTotal={prepaidTotal}
                          theirVendorCharges={this.state.theirVendorCharges}
                          myVendorCharges={this.state.myVendorCharges}
                          shipmentCurrency={shipmentCurrency}
                          grandTotals={grandTotals}
                          customFields={shipmentChargeLineItemCustomFields}
                        />
                      )}
                    </>
                  )}
                  {this.props.vendorFscRateTable || this.props.vendorAccRateTable ? (
                    <RateTableDetailsContainer
                      onRateTableClick={() => {
                        if (vendorAssignment && !this.props.editVendorFinancials) {
                          this.editVendorFinancials();
                        }
                      }}
                      financialSide="vendor"
                      isEditMode={vendorAssignment && canEdit && !is_quoting_limited_user}
                    />
                  ) : null}
                </div>
              )
            ) : null}
          </Col>
        </div>
      </div>
    );
  }
}

FinancialsExpanded = reduxForm({
  form: 'shipmentFinancialsForm',
  validate: validate,
  enableReinitialize: true,
  destroyOnUnmount: false,
  touchOnChange: true
})(FinancialsExpanded);

FinancialsExpanded = compose(
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentChargeLineItem),
  renameProp('customFields', 'shipmentChargeLineItemCustomFields'),
  withFlags('shipmentFinancialUpdate24')
)(FinancialsExpanded);

FinancialsExpanded = connect((state) => ({
  initialValues: {
    financials: state.shipmentdetails.selectedFinancials,
    markup: state.shipmentdetails.markup
  }
}))(FinancialsExpanded);

const selector = formValueSelector('shipmentFinancialsForm');

FinancialsExpanded = connect((state) => {
  const financialData = selector(state, 'financials');

  const financialTotals = [];
  const prepaidTotals = [];
  let currentTotal = 0;
  let currentPrepaidTotal = 0;
  if (financialData) {
    for (let i = 0; i < financialData.length; i++) {
      if (financialData[i] && financialData[i].unit_amount && financialData[i].unit_quantity) {
        let value = financialData[i].unit_amount;

        if (value && typeof value === 'string' && value.startsWith('$')) {
          value = value.slice(1);
        }
        if (value && typeof value === 'string') {
          value = value.replace(/,/g, '');
        }

        const valToAdd = parseFloat(value * financialData[i].unit_quantity);

        if (!isNaN(valToAdd)) {
          financialTotals.push(valToAdd);
          currentTotal += valToAdd;
        } else {
          financialTotals.push(0);
        }
      } else {
        financialTotals.push(0);
      }
      if (financialData[i] && financialData[i].prepaid_amount) {
        let prepaidValue = financialData[i].prepaid_amount;

        if (prepaidValue && typeof prepaidValue === 'string' && prepaidValue.startsWith('$')) {
          prepaidValue = prepaidValue.slice(1);
        }
        if (prepaidValue && typeof prepaidValue === 'string') {
          prepaidValue = prepaidValue.replace(/,/g, '');
        }

        const prepaidValToAdd = parseFloat(prepaidValue);

        if (!isNaN(prepaidValToAdd)) {
          prepaidTotals.push(prepaidValToAdd);
          currentPrepaidTotal += prepaidValToAdd;
        } else {
          prepaidTotals.push(0);
        }
      } else {
        prepaidTotals.push(0);
      }
    }
  }
  let markup = selector(state, 'markup');

  if (markup && markup.startsWith('$')) {
    markup = markup.slice(1);
  }
  if (markup) {
    markup = markup.replace(/,/g, '');
  }

  const margin = selector(state, 'margin');

  return {
    financialTotals,
    prepaidTotals,
    currentPrepaidTotal,
    currentTotal,
    markup,
    margin
  };
})(FinancialsExpanded);
