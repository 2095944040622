import {Configuration, UserPreferences} from '@shipwell/backend-core-sdk';
import {
  ShipwellUiApiShipwellUiSettlementsDashboardDeliveredGetRequest,
  ShipwellUiApi as ShipwellUiApiSingleRequestParam,
  PaginatedAlertsDashBoardConfiguration,
  AlertsDashBoardConfiguration,
  AlertDashboardSlimShipment,
  AlertDashboardSlimCarrierRelationship,
  ShipwellUiApiShipwellUiShipmentsShipmentIdConsolidateLineItemsPostRequest,
  ShipwellUiApi,
  ShipmentIdsAlertMetadata,
  ShipwellUiApiShipwellUiAlertsDashboardConfigurationGetRequest,
  ShipwellUiApiShipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdGetRequest,
  ShipwellUiApiShipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdAlertCountGetRequest,
  ShipwellUiApiShipwellUiAlertsDashboardCarrierRelationshipsCarrierRelationshipIdGetRequest,
  ShipwellUiApiShipwellUiShipmentsShipmentIdBillToGetRequest,
  ShipwellUiApiShipwellUiShipmentsStatusGetRequest,
  ShipwellUiApiShipwellUiSettlementsDashboardGetRequest,
  ShipwellUiApiShipwellUiAlertsDashboardShipmentsShipmentIdGetRequest,
  ShipwellUiApiShipwellUiEmailNotificationsPostRequest
} from '@shipwell/backend-core-singlerequestparam-sdk';

import {basePath} from 'App/api/config';
import {getAccessToken} from 'App/api/utils';

const shipwellUiApi = new ShipwellUiApi(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

const shipwellUiApiSingleRequestParam = new ShipwellUiApiSingleRequestParam(
  new Configuration({
    basePath,
    apiKey: getAccessToken
  })
);

export function sendEmailNotificationPromise(body: ShipwellUiApiShipwellUiEmailNotificationsPostRequest) {
  return shipwellUiApi.shipwellUiEmailNotificationsPost(body);
}

export async function getAlertsConfigurations(
  opts: ShipwellUiApiShipwellUiAlertsDashboardConfigurationGetRequest
): Promise<PaginatedAlertsDashBoardConfiguration> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardConfigurationGet(opts);
  return response.data;
}

export async function getAlertsConfiguration({
  alertsDashboardConfigurationId
}: ShipwellUiApiShipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdGetRequest): Promise<AlertsDashBoardConfiguration> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdGet({
    alertsDashboardConfigurationId
  });
  return response.data as AlertsDashBoardConfiguration;
}

export function getAlertCountByConfigId({
  alertsDashboardConfigurationId
}: ShipwellUiApiShipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdAlertCountGetRequest) {
  return shipwellUiApi.shipwellUiAlertsDashboardConfigurationAlertsDashboardConfigurationIdAlertCountGet({
    alertsDashboardConfigurationId
  });
}

export async function getAlertsDashboardShipment({
  shipmentId,
  resolvedAlerts
}: ShipwellUiApiShipwellUiAlertsDashboardShipmentsShipmentIdGetRequest): Promise<AlertDashboardSlimShipment> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardShipmentsShipmentIdGet({shipmentId, resolvedAlerts});
  return response.data as AlertDashboardSlimShipment;
}

export async function getAlertsDashboardCarrier({
  carrierRelationshipId,
  resolvedAlerts
}: ShipwellUiApiShipwellUiAlertsDashboardCarrierRelationshipsCarrierRelationshipIdGetRequest): Promise<AlertDashboardSlimCarrierRelationship> {
  const response = await shipwellUiApi.shipwellUiAlertsDashboardCarrierRelationshipsCarrierRelationshipIdGet({
    carrierRelationshipId,
    resolvedAlerts
  });
  return response.data as AlertDashboardSlimCarrierRelationship;
}

export function getUserWatchedShipments() {
  return shipwellUiApi.shipwellUiUserWatchedShipmentsGet();
}

export function getCurrentBillTo({shipmentId, quote}: ShipwellUiApiShipwellUiShipmentsShipmentIdBillToGetRequest) {
  return shipwellUiApi.shipwellUiShipmentsShipmentIdBillToGet({shipmentId, quote});
}

export function getShipmentStatuses({shipmentId}: ShipwellUiApiShipwellUiShipmentsStatusGetRequest) {
  return shipwellUiApi.shipwellUiShipmentsStatusGet({shipmentId});
}

export function getUserPreferences() {
  return shipwellUiApi.shipwellUiUserPreferencesGet();
}

export function updateUserPreferences(userPreferences: UserPreferences) {
  return shipwellUiApi.shipwellUiUserPreferencesPut({
    userPreferences
  });
}

export function getSettlementsDashboard(params?: ShipwellUiApiShipwellUiSettlementsDashboardGetRequest) {
  return shipwellUiApiSingleRequestParam.shipwellUiSettlementsDashboardGet(params || {});
}

export const getSettlementsDeliveredDashboard = (
  requestParameters?: ShipwellUiApiShipwellUiSettlementsDashboardDeliveredGetRequest
) => shipwellUiApiSingleRequestParam.shipwellUiSettlementsDashboardDeliveredGet(requestParameters);

export const getDocumentShortNames = () => shipwellUiApi.shipwellUiShipmentDocTypesShortNamesGet();

export const watchShipment = (shipmentId: string) => {
  return shipwellUiApi.shipwellUiUserWatchedShipmentsPost({
    inlineObject25: {
      shipment_id: shipmentId
    }
  });
};

export const stopWatchingShipment = (shipmentId: string) => {
  return shipwellUiApi.shipwellUiUserWatchedShipmentsDelete({
    inlineObject26: {
      shipment_id: shipmentId
    }
  });
};

export const consolidateLineItems = ({
  shipmentId,
  itemConsolidation
}: ShipwellUiApiShipwellUiShipmentsShipmentIdConsolidateLineItemsPostRequest) => {
  return shipwellUiApiSingleRequestParam.shipwellUiShipmentsShipmentIdConsolidateLineItemsPost({
    shipmentId,
    itemConsolidation
  });
};

export const getShipmentMetaDataAlerts = (shipmentIds: ShipmentIdsAlertMetadata['shipment_ids']) => {
  return shipwellUiApiSingleRequestParam.shipwellUiShipmentMetadataAlertsPost({
    shipmentIdsAlertMetadata: {shipment_ids: shipmentIds}
  });
};
