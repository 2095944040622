import {ChargeLineItemCategory, Shipment} from '@shipwell/backend-core-sdk';
import {FormikSelect, FormikTextInput, DeprecatedButton, FormikInlineGroupSelect} from '@shipwell/shipwell-ui';
import {Field, Form, Formik, FormikHelpers} from 'formik';
import {useFlags} from 'launchdarkly-react-client-sdk';

import {FreightInvoiceChargeLineItemsWithFormMetadata} from '../../types';
import {FreightInvoiceChargeLineItemSchema} from './validationSchema';
import {groupChargeCodesInlineGroupSelect, OptionMetadataType} from 'App/utils/chargeCodesHelpers';
import {useSortedChargeCategories, useGetChargeCodes} from 'App/data-hooks';
import React from 'react';

const getFreightInvoiceLineItemDefaultValues = (shipmentCurrencyOfRecord: Shipment['preferred_currency']) => {
  return {
    category: '',
    description: '',
    quantity: '',
    unit_amount: {value: undefined, currency: shipmentCurrencyOfRecord},
    add_to_invoice: true,
    create_as_financial_line_item: true
  };
};

interface NewLineItemFormProps {
  submitLineItem: (obj: FreightInvoiceChargeLineItemsWithFormMetadata) => void;
  toggleShowNewLineItemForm: () => void;
  shipmentCurrencyOfRecord: Shipment['preferred_currency'];
}

export const NewFinancialLineItemForm = ({
  submitLineItem,
  toggleShowNewLineItemForm,
  shipmentCurrencyOfRecord
}: NewLineItemFormProps) => {
  const handleSubmit = (
    values: FreightInvoiceChargeLineItemsWithFormMetadata,
    setSubmitting: FormikHelpers<FreightInvoiceChargeLineItemsWithFormMetadata>['setSubmitting']
  ) => {
    submitLineItem(values);
    setSubmitting(false);
    toggleShowNewLineItemForm();
  };
  const {data: chargeCategoryData} = useSortedChargeCategories();
  const {shipmentFinancialUpdate24} = useFlags();
  const {data: chargeCodes} = useGetChargeCodes();
  const chargeCodesInlineGroupOptions = groupChargeCodesInlineGroupSelect(chargeCodes?.data);

  const formatOptionLabel = ({label, categoryDisplay}: OptionMetadataType, {context}: {context: string}) =>
    context === 'value' ? <div>{`${label} (${categoryDisplay})`}</div> : context === 'menu' && <div>{label}</div>;
  return (
    <Formik
      validationSchema={FreightInvoiceChargeLineItemSchema}
      initialValues={getFreightInvoiceLineItemDefaultValues(shipmentCurrencyOfRecord)}
      onSubmit={(values, {setSubmitting}) => handleSubmit(values, setSubmitting)}
    >
      {({isSubmitting, handleSubmit, setFieldValue}) => (
        <Form noValidate>
          <div className="border-b border-b-sw-border  bg-sw-active-light p-2">
            {/*grid gap accounts for some of the container width */}
            <div className="grid grid-cols-[39%,25%,16%,16%] gap-x-2 pb-4 pt-2">
              {shipmentFinancialUpdate24 ? (
                <Field
                  component={FormikInlineGroupSelect}
                  name="category"
                  label="Charge Item"
                  required
                  simpleValue
                  options={chargeCodesInlineGroupOptions}
                  clearable={false}
                  formatOptionLabel={formatOptionLabel}
                  onChange={(event: string) => {
                    const selectedChargeCode = chargeCodes?.data?.find((chargeCode) => chargeCode.id === event);
                    setFieldValue('category', selectedChargeCode?.category);
                    setFieldValue('description', selectedChargeCode?.name);
                    setFieldValue('charge_code', selectedChargeCode?.code);
                  }}
                />
              ) : (
                <Field
                  component={FormikSelect}
                  name="category"
                  label="Line Item"
                  required
                  simpleValue
                  options={chargeCategoryData}
                  getOptionValue={(option: ChargeLineItemCategory) => option.id}
                  getOptionLabel={(option: ChargeLineItemCategory) => option.name}
                  clearable={false}
                />
              )}
              <Field component={FormikTextInput} name="description" label="Description" clearable={false} />
              <Field name="charge_code" type="hidden" />
              <Field component={FormikTextInput} name="quantity" type="number" label="Qty" required clearable={false} />
              <Field
                component={FormikTextInput}
                name="unit_amount.value"
                type="number"
                label="Rate"
                required
                clearable={false}
              />
            </div>
            <div className="flex justify-end gap-2">
              <DeprecatedButton
                variant="secondary"
                size="small"
                disabled={isSubmitting}
                onClick={toggleShowNewLineItemForm}
              >
                Cancel
              </DeprecatedButton>
              <DeprecatedButton
                type="submit"
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  e.preventDefault();
                  handleSubmit();
                }}
                size="small"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save
              </DeprecatedButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
