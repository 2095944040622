import {Dispatch as ReactDispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {Shipment} from '@shipwell/backend-core-singlerequestparam-sdk';
import {Tabs, Tab, TabList, TabPanel} from '@shipwell/shipwell-ui';
import {Action, Dispatch} from 'redux';
import {PurchaseOrder} from '@shipwell/corrogo-sdk';
import {Form, Formik, FormikErrors} from 'formik';
import {useUpdatesMultipleOrderStops} from '../../hooks/useUpdatesMultipleOrderStops';
import {AddOrdersToShipmentFooter} from '../ModalFooter';
import {AddOrdersToShipmentSharedStartEndPoint} from '../AddOrdersToShipmentSharedStartEndPoint';
import UpdateOrderDatesForm from './UpdateOrderDatesForm/UpdateOrderDatesForm';
import {validationSchema} from './schema';
import {getInitialValues, InitialValuesOrderDates} from './initialValues';
import {addOrdersToShipmentPayloadAdapter, hasBothPlanWindows} from './utils';
import {ContainerType} from 'App/containers/orders/components/AddOrdersButton';
import ShipwellLoader from 'App/common/shipwellLoader';
import {getOrdersList} from 'App/api/corrogo/orders';
import {parseV3ApiError} from 'App/api/typedUtils';
import {shipmentsShipmentIdGet} from 'App/actions/shipmentdetails';
import {useAddOrdersToShipment} from 'App/data-hooks/shipmentAssembly/useAddOrdersToShipment';
import {WithStatusToastProps} from 'App/components/withStatusToasts';

export type ShipmentStopPlanningWindow = {
  planning_window?: {
    start: string;
    end: string;
  };
};

interface UpdateOrderDatesProps {
  selectedOrderIds: string[];
  shipmentId: string;
  setActiveContainer: ReactDispatch<SetStateAction<ContainerType>>;
  onClose: () => void;
  activeContainer: ContainerType;
  dispatch?: Dispatch<Action<typeof shipmentsShipmentIdGet>>;
  setSuccess: WithStatusToastProps['setSuccess'];
  setError: WithStatusToastProps['setError'];
  removeSelectedById: (id: string) => void;
  shipment: Shipment | undefined;
}

const UpdateOrderDates = ({
  selectedOrderIds,
  onClose,
  setActiveContainer,
  activeContainer,
  shipmentId,
  dispatch,
  setSuccess,
  setError,
  removeSelectedById,
  shipment
}: UpdateOrderDatesProps) => {
  const [initialValue, setInitialValue] = useState<InitialValuesOrderDates>(getInitialValues([]));
  const [tab, setTab] = useState<number>(0);
  const {UpdateOrdersDates, isLoadingOrdersDates} = useUpdatesMultipleOrderStops();
  const {addOrdersToShipmentAsync, isAddingOrdersToShipment} = useAddOrdersToShipment();
  const isLoading = isLoadingOrdersDates || isAddingOrdersToShipment;
  const shipmenPickupWindown = shipment?.stops && (shipment.stops[0] as ShipmentStopPlanningWindow).planning_window;
  const shipmenDeliveryWindown =
    shipment?.stops && (shipment.stops[shipment?.stops.length - 1] as ShipmentStopPlanningWindow).planning_window;

  const updateInitialValues = useCallback(async () => {
    if (selectedOrderIds.length) {
      const selectedOrders = await getOrdersList({idIn: selectedOrderIds.join()});
      setInitialValue(getInitialValues(selectedOrders.data as PurchaseOrder[]));
    }
  }, [selectedOrderIds]);

  useEffect(() => {
    void updateInitialValues();
  }, [updateInitialValues]);

  const handleSubmit = async (
    values: InitialValuesOrderDates,
    validateForm?: () => Promise<Partial<FormikErrors<InitialValuesOrderDates>>>
  ) => {
    const errors = validateForm ? await validateForm() : {};

    if (Object.keys(errors).length > 0) {
      setError(
        'Error!',
        "One or more orders do not have a Pickup and/or Delivery Date. Select 'Modify Orders’ to update orders with missing date(s)."
      );
      return;
    }
    try {
      await UpdateOrdersDates({orders: values.orders});
      await addOrdersToShipmentAsync(
        addOrdersToShipmentPayloadAdapter({
          initialValues: values,
          selectedOrderIds,
          shipmentId
        })
      );

      if (dispatch) {
        void dispatch(shipmentsShipmentIdGet(shipmentId, {}) as unknown as Action);
      }
      onClose();
      setSuccess('Success', 'Orders were successfully added to shipment.');
    } catch (error) {
      setError('Error!', parseV3ApiError(error).detail);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize
      >
        {({values, validateForm}) => (
          <Form>
            {activeContainer === 'updateOrderDates' && (
              <>
                {!!selectedOrderIds.length && <ShipwellLoader />}
                <div>
                  <span className="font-bold">
                    All stops of an order are required to have a date before being consolidated into a shipment. 1 or
                    more of the orders you selected does not have dates. Select a date for all stops to continue.
                  </span>
                </div>

                <Tabs selectedIndex={tab} onSelect={(tabIndex: number) => setTab(tabIndex)} className="w-full">
                  <TabList className="flex justify-center">
                    <Tab key="withoutDates" className="w-1/2 p-2 text-center">
                      {`Orders without Dates (${values.orders.filter((order) => !hasBothPlanWindows(order)).length})`}
                    </Tab>
                    <Tab key="withDates" className="w-1/2 p-2 text-center">
                      {`Orders with Dates (${values.orders.filter((order) => hasBothPlanWindows(order)).length})`}
                    </Tab>
                  </TabList>
                  <p className="leading-extra-tight text-grey-5 mt-4 text-left text-xs font-normal italic">
                    Dates are required to add an order to a shipment.
                  </p>
                  <TabPanel key="withoutDates">
                    {values.orders
                      .filter((order) => !hasBothPlanWindows(order))
                      .map((order) => (
                        <div key={order.orderId} className="p-4">
                          <UpdateOrderDatesForm
                            orderNumber={order.orderNumber}
                            type="ship_from"
                            orderStop={order.ship_from}
                            index={order.formIndex}
                            shipmenPickupWindown={shipmenPickupWindown}
                            shipmenDeliveryWindown={shipmenDeliveryWindown}
                            removeSelectedById={removeSelectedById}
                            orderId={order.orderId}
                          />
                          <UpdateOrderDatesForm
                            orderNumber={order.orderNumber}
                            type="ship_to"
                            orderStop={order.ship_to}
                            index={order.formIndex}
                            shipmenPickupWindown={shipmenPickupWindown}
                            shipmenDeliveryWindown={shipmenDeliveryWindown}
                            removeSelectedById={removeSelectedById}
                            orderId={order.orderId}
                          />
                        </div>
                      ))}
                  </TabPanel>
                  <TabPanel key="withDates">
                    {values.orders
                      .filter((order) => hasBothPlanWindows(order))
                      .map((order) => (
                        <div key={order.orderId} className="p-4">
                          <UpdateOrderDatesForm
                            orderNumber={order.orderNumber}
                            type="ship_from"
                            orderStop={order.ship_from}
                            index={order.formIndex}
                            shipmenPickupWindown={shipmenPickupWindown}
                            shipmenDeliveryWindown={shipmenDeliveryWindown}
                            removeSelectedById={removeSelectedById}
                            orderId={order.orderId}
                          />
                          <UpdateOrderDatesForm
                            orderNumber={order.orderNumber}
                            type="ship_to"
                            orderStop={order.ship_to}
                            index={order.formIndex}
                            shipmenPickupWindown={shipmenPickupWindown}
                            shipmenDeliveryWindown={shipmenDeliveryWindown}
                            removeSelectedById={removeSelectedById}
                            orderId={order.orderId}
                          />
                        </div>
                      ))}
                  </TabPanel>
                </Tabs>
              </>
            )}

            {activeContainer === 'modifyStops' && (
              <div className="mb-4 flex flex-col gap-4">
                <AddOrdersToShipmentSharedStartEndPoint numberOfOrders={selectedOrderIds.length} />
              </div>
            )}

            <AddOrdersToShipmentFooter
              onClose={onClose}
              isAddingOrdersToShipment={isLoading}
              selectedOrderIds={selectedOrderIds}
              setActiveContainer={setActiveContainer}
              handleAddOrdersToShipment={() => void handleSubmit(values, validateForm)}
              activeContainer={activeContainer}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateOrderDates;
