import {FC, useMemo, ChangeEvent} from 'react';
import {Formik, Form, Field} from 'formik';
import {FormikCheckbox, FormikSelect, FormikTextInput, FormikTextarea, SvgIcon, Tooltip} from '@shipwell/shipwell-ui';
import {
  Template,
  TemplateAutoGenerateRule,
  TemplateDocumentType,
  TemplateMode,
  TemplateStatus,
  UpdateTemplate
} from '@shipwell/template-builder-sdk';
import {generateTemplateInitialValues} from './initialValues';
import {templateValidationSchema} from './schema';
import {createOptionsFromSDK, dotSnakeCaseToTileCase} from 'App/containers/userCompany/templates/utils/formtData';
import ModalFormFooter from 'App/formComponents/formSections/formFooter/modalFormFooter';

export interface EditTemplateFormProps {
  onClose?: () => void;
  handleSubmit: (values: UpdateTemplate) => Promise<void>;
  template: Template;
}
const autoGenerateRulesOptions = [
  {event_name: 'shipment.created', label: 'Shipment Created'},
  {event_name: 'shipment.carrier_assigned', label: 'Shipment Carrier Assigned'},
  {event_name: 'freight_invoice.received', label: 'Freight Invoice Received'}
];

export const EditTemplateForm: FC<EditTemplateFormProps> = ({handleSubmit, template, onClose}) => {
  const initialValues = useMemo(() => generateTemplateInitialValues(template), [template]);
  const templateStatusOptions = useMemo(() => createOptionsFromSDK(TemplateStatus), []);
  const templateModeOptions = useMemo(() => createOptionsFromSDK(TemplateMode, true, 'All Modes'), []);
  const templateDocumentTypeOptions = useMemo(() => createOptionsFromSDK(TemplateDocumentType), []);
  const formatOptionLabel = ({event_name}: {event_name: string}) => <div>{dotSnakeCaseToTileCase(event_name)}</div>;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={templateValidationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({isSubmitting, setFieldValue}) => (
        <Form className="mb-10 grid gap-3 pb-10">
          <Field
            key="name"
            name="name"
            value={template.name}
            label="Template Name"
            component={FormikTextInput}
            disabled
          />
          <Field key="description" name="description" label="Description" component={FormikTextarea} />
          <div className="grid grid-cols-2 gap-3">
            <Field
              simpleValue
              label="Document Type"
              component={FormikSelect}
              name="document_type"
              options={templateDocumentTypeOptions}
            />
            <Field simpleValue label="Status" component={FormikSelect} name="status" options={templateStatusOptions} />
          </div>
          <Field
            name="allowed_modes"
            component={FormikSelect}
            options={templateModeOptions}
            label="Modes"
            onChange={(values: string[]) => {
              if (values.some((option) => option === 'All Modes')) {
                return setFieldValue(
                  'allowed_modes',
                  templateModeOptions.filter((option) => option.value !== 'All Modes').map((option) => option.value)
                );
              }
              setFieldValue('allowed_modes', values);
            }}
            placeholder="Select modes"
            isMulti
            simpleValue
            maxMenuHeight={90}
          />
          <Field
            formatOptionLabel={formatOptionLabel}
            name="auto_generate_rules"
            component={FormikSelect}
            options={autoGenerateRulesOptions}
            getOptionValue={(option: TemplateAutoGenerateRule) => option.event_name}
            label="Event Generation"
            placeholder="Select Event"
            isMulti
            maxMenuHeight={90}
          />

          <div className="grid grid-cols-4 gap-2">
            <div className="flex justify-between gap-1 items-center content-center">
              <div className="basis-11/12">
                <Field
                  name="configuration.generate_per_stop"
                  label="By Stop"
                  component={FormikCheckbox}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('configuration.generate_per_stop', e.target.checked)
                  }
                  checked={template.configuration.generate_per_stop}
                />
              </div>

              <div className="w-full items-center content-center">
                <Tooltip
                  tooltipClassname="w-[237px]"
                  placement="top"
                  tooltipContent={
                    <div className="max-w-xs">
                      <div className="font-bold">By Stop</div>
                      <div>
                        This template will be generated on a by-stop basis so each delivery stop will represent a
                        separate page(s) within the document generated from this template.
                      </div>
                    </div>
                  }
                  trigger="hover"
                >
                  <SvgIcon name="InfoOutlined" width="20" height="20" color="$sw-icon" />
                </Tooltip>
              </div>
            </div>

            <div className="flex justify-between gap-1 items-center content-center">
              <div className="basis-full">
                <Field
                  name="configuration.include_in_email"
                  label="By Email"
                  component={FormikCheckbox}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('configuration.include_in_email', e.target.checked)
                  }
                  checked={template.configuration.include_in_email}
                />
              </div>

              <div className="w-full items-center content-center">
                <Tooltip
                  tooltipClassname="w-[237px]"
                  placement="top"
                  tooltipContent={
                    <div className="max-w-xs">
                      <div className="font-bold">By Email</div>
                      <div>
                        This template will be included in emails that are designated to include the document template
                        type assuming configurations of the template match the configurations of the shipment.
                      </div>
                    </div>
                  }
                  trigger="hover"
                >
                  <SvgIcon name="InfoOutlined" width="20" height="20" color="$sw-icon" />
                </Tooltip>
              </div>
            </div>
          </div>

          <ModalFormFooter isSubmitting={isSubmitting} onCancel={onClose} primaryActionName={'Save'} />
        </Form>
      )}
    </Formik>
  );
};
