import {Select, SvgIcon} from '@shipwell/shipwell-ui';
import {Address} from '@shipwell/backend-core-singlerequestparam-sdk';
import {LocalVal} from './useLocationFilter';
import MultiAddressSearch from 'App/formComponents/fields/multiAddressSearch';
import {FlexBox} from 'App/components/Box';

export const LocationFilter = ({
  label,
  onChange,
  radius,
  onRadiusChange,
  isFetching = false,
  values,
  showRadiusSelect,
  useLatLong = false,
  searchAddressBook = false
}: {
  label: string;
  onChange: (addresses: (Address | LocalVal)[]) => Promise<void>;
  radius?: string;
  onRadiusChange?: (newRadius: string) => Promise<void>;
  isFetching?: boolean;
  values: LocalVal[];
  showRadiusSelect?: boolean;
  useLatLong?: boolean;
  searchAddressBook?: boolean;
}) => {
  return (
    <FlexBox direction="col" gap="s">
      {isFetching ? (
        <SvgIcon name="LoadingDots" />
      ) : (
        <MultiAddressSearch
          value={values}
          searchAddressBook={searchAddressBook}
          searchMaps
          searchStateProvince
          label={label}
          allowCreate
          onChange={onChange}
          useBaseComponent
          ignoreWarnings
          ignoreFieldWarnings={['postal_code']}
        />
      )}
      {showRadiusSelect && useLatLong ? (
        <Select
          label="Radius"
          value={radius}
          simpleValue
          onChange={onRadiusChange}
          clearable={false}
          options={[
            {value: '0', label: 'Specific Location'},
            {value: '50', label: '50+ miles'},
            {value: '100', label: '100+ miles'},
            {value: '150', label: '150+ miles'},
            {value: '200', label: '200+ miles'},
            {value: '300', label: '300+ miles'}
          ]}
        />
      ) : null}
    </FlexBox>
  );
};
