import {cloneElement, Component} from 'react';
import {connect} from 'react-redux';
import {get, uniqBy} from 'lodash';
import {reduxForm, initialize, destroy} from 'redux-form';
import pluralize from 'pluralize';
import {compose, renameProp} from 'recompose';
import {Toast, Loader} from '@shipwell/shipwell-ui';
import {CustomFieldEntityTypesEnum} from '@shipwell/backend-core-singlerequestparam-sdk';
import {getShipmentDetails} from 'App/actions/shipments';
import {scrollToFirstErrorField} from 'App/utils/globals';
import {initialFormValues} from 'App/containers/quotes/create/utils/constants';
import {validation} from 'App/containers/quotes/create/utils/validation';
import {
  createQuote,
  onCreateQuoteSuccess,
  normalizeShipmentForQuote
} from 'App/containers/quotes/create/utils/createQuote';
import {METRIC, OTHER, CELSIUS, convertFahrenheitToCelsius} from 'App/utils/internationalConstants';
import store from 'App/routes/store';
import {withCustomFieldsProvider} from 'App/data-hooks';
import {userHasDefaultShipmentTags} from 'App/utils/shipmentTags';
import {withCreateParcelRateRequest} from 'App/api/quoting/hooks/parcel/useCreateParcelRateRequest';
import './styles.scss';

/**
 * Quick Quote
 * Container for new quote creation flow
 */
export class QuickQuote extends Component {
  constructor(props, context, ...args) {
    super(props, context, ...args);

    this.shipment;

    this.state = {
      showConsolidationToast: false
    };
  }
  componentDidMount() {
    const {params, location} = this.props;
    const shipmentParamId = params && params.shipment_id;
    const shipmentQueryId = location && location.query.from_shipment;
    const shipmentId = shipmentParamId || shipmentQueryId;

    if (shipmentId) {
      this.getShipmentDetails(shipmentId);
    }
    const showConsolidationToast = get(location, 'query.showSuccess', false);
    this.setState({showConsolidationToast});
  }

  componentDidUpdate(prevProps) {
    const {user, params, location, getParcelRateRequestByShipmentId} = this.props;
    const {getParcelRateRequestByShipmentId: prevGetParcelRateRequestByShipmentId} = prevProps;
    const {parcelRateRequest} = getParcelRateRequestByShipmentId || {};
    const shipmentId = params && params.shipment_id;
    const isEditView = !!shipmentId;
    const shipmentQueryId = location && location.query.from_shipment;
    const shipmentIdToQuery = shipmentId || shipmentQueryId;

    /** Need to wait for Auth token to be set */
    if (user !== prevProps.user && shipmentIdToQuery) {
      this.getShipmentDetails(shipmentIdToQuery);
    }
    const showConsolidationToast = get(location, 'query.showSuccess', false);
    const prevShowConsolidationToast = get(prevProps, 'location.query.showSuccess', false);
    if (showConsolidationToast && showConsolidationToast !== prevShowConsolidationToast) {
      this.setState({showConsolidationToast});
    }
    if (
      this.shipment &&
      parcelRateRequest?.id &&
      parcelRateRequest?.id !== prevGetParcelRateRequestByShipmentId?.parcelRateRequest?.id
    ) {
      this.props.dispatch(
        initialize('newQuoteForm', normalizeShipmentForQuote(this.shipment, !isEditView, {parcelRateRequest}))
      );
    }
  }

  componentWillUnmount() {
    const {form} = this.props;

    this.props.dispatch(destroy(form));
  }

  getShipmentDetails(shipmentId) {
    const validId = /^[\w\d-]*$/.test(shipmentId);

    if (validId) {
      this.handleGetParcelRateRequest(shipmentId);
      this.props
        .dispatch(getShipmentDetails(shipmentId))
        .then(this.handleShipmentRequestSuccess.bind(this))
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleGetParcelRateRequest(shipmentId) {
    if (shipmentId) {
      const {setShipmentIdToFetchRateRequest} = this.props;
      if (setShipmentIdToFetchRateRequest) {
        setShipmentIdToFetchRateRequest(shipmentId);
      }
    }
  }

  handleShipmentRequestSuccess(resp) {
    const {
      params = {},
      location,
      companyTags = [],
      defaultShipmentTags = [],
      getParcelRateRequestByShipmentId = {}
    } = this.props;
    const isEditView = params.shipment_id;
    const isClone = location.query.from_shipment;
    const isConsolidation = location.query.ordersConsolidated;

    const {parcelRateRequest} = getParcelRateRequestByShipmentId;

    if (resp.status === 400 && resp.error_description === 'Not found.') {
      this.props.router.replace('/shipments');
    } else if (resp.status === 200) {
      const shipment = resp.details;

      if (!isEditView) {
        shipment.stops = shipment.stops.map(
          ({id, confirmed_arrival_at, confirmed_departure_at, carrier_specified_eta, predictive_model_eta, ...stop}) =>
            (stop = {
              ...stop,
              status_reason_code: null
            })
        );
        delete shipment.id;
        shipment.most_recently_awarded_quote = null;
        shipment.most_recently_awarded_quote_id = null;
      }

      // if cloning or consolidating, map shipment tag ids and user default tag ids to full tag objects and apply the combined values to the shipment
      if (isConsolidation || isClone) {
        const userDefaultShipmentTags = userHasDefaultShipmentTags({companyTags, defaultShipmentTags})
          ? companyTags.filter(({id}) => defaultShipmentTags.includes(id))
          : [];
        const shipmentTags =
          shipment.metadata?.tags?.length > 0
            ? shipment.metadata.tags.map((tag) => (tag.id ? tag : companyTags.find(({id}) => id === tag)))
            : [];

        shipment.metadata = {...shipment.metadata, tags: uniqBy([...shipmentTags, ...userDefaultShipmentTags], 'id')};
      }

      //don't override the po # if its a consolidation action
      if (!isConsolidation && !isEditView) {
        shipment.purchase_order_number = '';
      }

      if (!isEditView) {
        shipment.pro_number = '';
        shipment.customer_reference_number = '';
        shipment.name = '';
        shipment.pickup_number = '';
        shipment.bol_number = '';
        delete shipment.state;
      }

      if (shipment.line_items.length > 0) {
        //remove refs to purchase orders
        shipment.line_items.forEach((item) => {
          delete item.purchase_order;
        });
      }

      shipment.stops = shipment.stops.map((stop) => ({
        ...stop,
        unconfirmed_arrival_at: null,
        unconfirmed_departure_at: null,
        confirmed_arrival_at: null,
        confirmed_departure_at: null,
        trip_management_eta: null,
        trip_managent_eta_last_updated: null,
        status: null,
        planned_time_window_start: stop.planned_time_window_start || '08:00',
        planned_time_window_end: stop.planned_time_window_end || '18:00'
      }));

      //perform conversions to user's preferred units if necessary
      const {unitPreferences} = store.getState().userCompany;

      if (unitPreferences && (unitPreferences.system === METRIC || unitPreferences.system === OTHER)) {
        // do conversions from imperial value in database
        // temperature F ==> C
        if (shipment.temperature_lower_limit && unitPreferences.temperatureUnit === CELSIUS) {
          shipment.temperature_lower_limit = convertFahrenheitToCelsius(shipment.temperature_lower_limit);
        }
        if (shipment.temperature_upper_limit && unitPreferences.temperatureUnit === CELSIUS) {
          shipment.temperature_upper_limit = convertFahrenheitToCelsius(shipment.temperature_upper_limit);
        }
      }

      shipment.manual_total_weight = shipment.total_weight_override?.value && shipment.total_weight_override?.value > 0;
      shipment.manual_total_value = shipment.total_declared_value && shipment.total_declared_value > 0;
      shipment.manual_quantity = shipment.total_quantity_override && shipment.total_quantity_override > 0;
      this.shipment = shipment;
      this.props.dispatch(
        initialize('newQuoteForm', normalizeShipmentForQuote(shipment, !isEditView, {parcelRateRequest}))
      );
    }
  }

  render() {
    const {showConsolidationToast} = this.state;
    const {location, getParcelRateRequestByShipmentId = {}} = this.props;

    const {isLoadingParcelRates} = getParcelRateRequestByShipmentId;

    return isLoadingParcelRates ? (
      <Loader show={isLoadingParcelRates}>Loading Rate Details ...</Loader>
    ) : (
      <div className="content-wrapper quote-creation-container">
        <div className="quick-quote-body">
          <div className="quick-quote-form">
            {this.props.initialized &&
              this.props.children &&
              cloneElement(this.props.children, {
                onSubmitSuccess: this.props.onSubmitSuccess,
                isEditForm: Boolean(this.props.params && this.props.params.shipment_id),
                router: this.props.router,
                submitSucceeded: this.props.submitSucceeded,
                stopCustomFields: this.props.stopCustomFields || [],
                isLoadingParcelRates
              })}
          </div>
        </div>
        <Toast
          show={showConsolidationToast}
          title="Shipment Created!"
          variant="success"
          anchor="top-right"
          delay="10000"
        >
          Your {pluralize('order', parseFloat(location.query.ordersConsolidated), true)}{' '}
          {pluralize('has', parseFloat(location.query.ordersConsolidated))} been consolidated into a quote.
        </Toast>
      </div>
    );
  }
}

export const defaultFormSettings = {
  form: 'newQuoteForm',
  validate: validation,
  keepDirtyOnReinitialize: false,
  onSubmit: createQuote,
  onSubmitSuccess: onCreateQuoteSuccess,
  onSubmitFail: scrollToFirstErrorField
};

const CreateQuickQuoteForm = reduxForm(defaultFormSettings)(QuickQuote);

/**
 * Order matters here: the component passed to withCustomFieldsProvider must be already wrapped in reduxForm
 * if the form is expected to receive and validate provided props
 *
 * The context provider for two different custom data entites (line items, stops) is all the way up here
 * so the form can recieve and validate these props. Since the second context collides with and overwrites the first,
 * we're renaming the first prop and passing it down to the child/consumer the old fashioned way.
 * Necessary until we're not using class components + reduxForm here and can provide and consume this data more elegantly.
 */

const CreateQuickQuoteFormWithCustomFields = compose(
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentStop),
  renameProp('customFields', 'stopCustomFields'),
  withCustomFieldsProvider(CustomFieldEntityTypesEnum.ShipmentLineItem),
  withCreateParcelRateRequest
)(CreateQuickQuoteForm);

export const CreateQuickQuote = connect((state) => {
  /** Grab persisted form entries from localStorage */
  const persistedForms = localStorage.getItem('reduxPersist:form');
  const persistedQuoteForm = persistedForms && JSON.parse(persistedForms).newQuoteForm;
  const valuesFromStorage = persistedQuoteForm && persistedQuoteForm.values;
  const {unitPreferences} = state.userCompany;
  // use the user's company preferences to determine default units to prepopulate
  const lineItemDefaults = {
    line_items: [
      {
        package_type: 'PLT',
        length_unit: unitPreferences.lengthUnit,
        weight_unit: unitPreferences.weightUnit,
        temp_unit: unitPreferences.temperatureUnit,
        value_per_piece_currency: unitPreferences.currency,
        length: '40',
        width: '48',
        stackable: false
      }
    ]
  };
  const initialValues = Object.assign({}, initialFormValues, lineItemDefaults, valuesFromStorage);
  return {
    user: state.auth.user,
    selectedShipment: state.shipments.selectedShipment,
    initialValues: initialValues,
    unitPreferences: state.userCompany.unitPreferences,
    currency: state.userCompany.currency,
    companyTags: state.shipments.tags,
    defaultShipmentTags: state.auth.user.default_shipment_tags
  };
})(CreateQuickQuoteFormWithCustomFields);

export {default as NewQuoteStopsAndLineItems} from './views/shipmentStopsAndLineItems';
